import {useHomeUpcomingDays} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {LoaderTile, Tile} from '../Tile';
import {FlexCol, FlexRow, H4, Subtitle} from '@quarks';
import {Icon, IconNames} from '@atoms';
import {useNavigate} from 'react-router-dom';
import {addDays, format} from 'date-fns';

type Props = {
  referenceDate: Date;
};

export const NextWorkdaysSimplified = ({referenceDate}: Props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isLoading, status, title, subtitle} = useHomeUpcomingDays(referenceDate, t);
  const icon: IconNames = status === 'OfficeWeek' ? 'office' : 'calendar';

  const nextDayDateKey = format(addDays(referenceDate, 1), 'yyyy-MM-dd');

  const handleTileClick = () => {
    navigate(`/calendar/${nextDayDateKey}`);
  };

  if (isLoading) {
    return <LoaderTile />;
  }

  const bgClassName = status === 'OfficeWeek' ? 'bg-energizing-yellow-500*' : 'bg-beige-500*';

  return (
    <Tile>
      <FlexCol width="100%">
        <FlexRow
          alignItems="center"
          className="cursor-pointer"
          aria-label={t('ChangeLocationRow')}
          onClick={handleTileClick}>
          <FlexRow
            justifyContent="center"
            alignItems="center"
            gap={16}>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              className={` ${bgClassName} h-12 w-12 rounded-lg shrink-0`}>
              <Icon
                icon={icon}
                size="24px"
              />
            </FlexCol>
            <FlexCol>
              <H4>{title}</H4>
              {subtitle?.length ? <Subtitle>{subtitle}</Subtitle> : null}
            </FlexCol>
          </FlexRow>
          <FlexRow
            alignItems="center"
            gap={16}>
            <Icon
              icon={'caretRight'}
              size="24px"
            />
          </FlexRow>
        </FlexRow>
      </FlexCol>
    </Tile>
  );
};
