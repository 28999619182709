import {RootState} from '@lib/store';
import {TypedUseSelectorHook, useSelector} from 'react-redux';

/**
 * @deprecated Prefer hooks from `@lib/store`
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// For performance tracking, use:
/*
export const useAppSelector: TypedUseSelectorHook<RootState> = (selector, equalityFn) => {
  return useSelector(withPerformanceTracking(selector), equalityFn);
};
*/
