import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {getDefaultBuildingId, useAppSelector} from '@lib/store';
import {nanoid} from '@reduxjs/toolkit';
import {OnboardingStep} from '../types';
import {useAreaOptions} from '@lib/logic';

interface OnboardingAreaProps {
  floorId?: string | null;
  initialValue: string | null;
  onBack?: () => void;
  onSelectArea: (selectedAreaId: string | null) => void;
}

export const OnboardingAreaCard = ({initialValue, floorId = null, onBack, onSelectArea}: OnboardingAreaProps) => {
  const {t} = useTranslation();
  const buildingId = useAppSelector(getDefaultBuildingId);
  const {getAreaOptionsForSelection} = useAreaOptions();
  const areaOptions = getAreaOptionsForSelection({buildingId, floorId});

  return (
    <OnboardingCard
      items={areaOptions}
      title={t('workplace:WhichAreaPreference')}
      description={t('workplace:WhichAreaPreferenceDescription')}
      currentStep={OnboardingStep.Area}
      initialValue={initialValue}
      renderItem={({item: area, selectedItem, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={area.value ?? nanoid()}
          onFocus={handleOnFocus}>
          <Input
            type="radio"
            name="area"
            checked={selectedItem === area.value}
            onChange={() => setSelectedItem(area.value)}
          />
          <LabelText>{area.label}</LabelText>
        </OnboardingItemCell>
      )}
      onBack={() => onBack?.()}
      onSelect={onSelectArea}
    />
  );
};
