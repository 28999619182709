import {useFeatureToggle} from '@hooks';
import {trackEvent} from '@lib/infrastructure';
import {MapType} from '@lib/store';
import {useCallback, useEffect, useState} from 'react';

export type MapTypePreference = MapType;

const DEFAULT_PREFERENCE: MapTypePreference = '2d';
const MAP_PREFERENCE_KEY = 'MAPIQ_MAP_TYPE_PREFERENCE';
export const THREE_D_SUPPORTED_NAMESPACES = ['HereAndNow_rooms', 'HereAndNow_explore', 'HereAndNow_workspaces'];

const writeToStorage = (key: string, pref: MapTypePreference) => {
  localStorage.setItem(key, pref);
};

export const useGlobalMapTypePreference = (namespace = '') => {
  const {ThreeDMapsAsDefault: default3dMap} = useFeatureToggle();

  const getFromStorage = useCallback(
    (key: string): MapTypePreference => {
      const storedValue = localStorage.getItem(key);

      if (storedValue === '2d') return '2d';
      if (storedValue === '3d') return '3d';

      return default3dMap ? '3d' : DEFAULT_PREFERENCE;
    },
    [default3dMap],
  );

  const key = namespace ? `${MAP_PREFERENCE_KEY}_${namespace}` : MAP_PREFERENCE_KEY;
  const [preference, setPreference] = useState(getFromStorage(key));

  useEffect(
    function syncToLocalStorage() {
      writeToStorage(key, preference);
      window.dispatchEvent(new Event('storage'));
    },
    [key, preference],
  );

  useEffect(
    function setupSyncFromLocalStorage() {
      const syncFromLocalStorage = () => {
        const value = getFromStorage(key);
        setPreference(value);
      };

      window.addEventListener('storage', syncFromLocalStorage);

      return function disposeStorageListener() {
        window.removeEventListener('storage', syncFromLocalStorage);
      };
    },
    [getFromStorage, key],
  );

  const trackedSetPreference = (newPreference: MapTypePreference) => {
    trackEvent('LocalRenderModePreferenceChange', {
      namespace: namespace || 'global',
      toType: newPreference,
      defaultType: (default3dMap ? '3d' : '2d') as MapTypePreference,
    });

    setPreference(newPreference);
  };

  const allowedPreference = THREE_D_SUPPORTED_NAMESPACES.includes(namespace) ? preference : '2d';

  return [allowedPreference, trackedSetPreference] as const;
};
