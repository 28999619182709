import {useMemo} from 'react';
import {useAppSelector, useModal} from '@hooks';
import {
  getSelectedBuildingNodeId,
  getFloorsByBuildingIdSorted,
  getInitialSelectedWorkspace,
  getAvailableDeskAmenityDataByBuildingId,
  getAvailableActivityTypeDataByBuildingId,
  getListOfSelectableWorkingSpacesByTypeNew,
} from '@lib/store';
import {ChipFilterNew} from '@molecules';
import {WorkspaceSelectionCell, ModalPage} from '@organisms';
import {Div, FlexCol, FlexRow, P, Subtitle} from '@quarks';
import {useTranslation} from 'react-i18next';
import {getDeskAmenityIcon} from '@utils';
import {Illustration} from '@atoms';
import {constructWorkspacesFilter, useFilterData} from '@lib/logic';

export type WorkspaceSelectionCardProps = {
  date: string;
  inviteeCount: number;
};

export const WorkspaceSelectionCard = ({date, inviteeCount = 0}: WorkspaceSelectionCardProps) => {
  const {closeModal, removePages} = useModal();
  const {t} = useTranslation();

  const initialSelectionParams = useMemo(() => ({selectedDate: date}), [date]);
  const baseLocation = useAppSelector((state) => getInitialSelectedWorkspace(state, initialSelectionParams));
  const selectedBuildingId = (useAppSelector(getSelectedBuildingNodeId) || baseLocation?.buildingId) ?? '';
  const floors = useAppSelector(getFloorsByBuildingIdSorted(selectedBuildingId));

  const availableDeskAmenityData = useAppSelector(getAvailableDeskAmenityDataByBuildingId(selectedBuildingId));
  const availableActivityTypeData = useAppSelector(getAvailableActivityTypeDataByBuildingId(selectedBuildingId));
  const {availableDeskAmenities} = availableDeskAmenityData;
  const {availableActivityTypes} = availableActivityTypeData;

  const listOfSelectableWorkingSpacesProps = useMemo(
    () => ({
      buildingId: selectedBuildingId,
      date,
      inviteeCount: inviteeCount,
    }),
    [selectedBuildingId, date, inviteeCount],
  );
  const listOfSelectableWorkingSpaces = useAppSelector((state) =>
    getListOfSelectableWorkingSpacesByTypeNew(state, listOfSelectableWorkingSpacesProps),
  );

  const filters = useMemo(
    () => constructWorkspacesFilter(floors, availableDeskAmenities, availableActivityTypes, getDeskAmenityIcon, t),
    [availableActivityTypes, availableDeskAmenities, floors, t],
  );
  const {filteredData: filteredAvailable} = useFilterData(
    'CalendarOverview_Workspaces',
    selectedBuildingId,
    listOfSelectableWorkingSpaces.Available,
    filters,
  );
  const {filteredData: filteredUnavailable} = useFilterData(
    'CalendarOverview_Workspaces',
    selectedBuildingId,
    listOfSelectableWorkingSpaces.Unavailable,
    filters,
  );

  const hasNoResults = !filteredAvailable.length && !filteredUnavailable.length;
  const allData = [
    {key: 'Available', data: filteredAvailable},
    {
      key: 'Unavailable',
      data: filteredUnavailable.filter((ws) => ws.workspaceBookingPolicyType !== 'NonBookable'),
    },
    {
      key: 'AdHoc',
      data: filteredUnavailable.filter((ws) => ws.workspaceBookingPolicyType === 'NonBookable'),
    },
  ];

  return (
    <ModalPage
      title={t('workplace:SelectWorkspace')}
      onBack={() => removePages(1)}
      onClose={closeModal}>
      <FlexCol gap={32}>
        <FlexRow
          justifyContent="flex-start"
          gap={8}>
          <ChipFilterNew
            filterSlice="CalendarOverview_Workspaces"
            areFilterTransparent
            goBackFromFilterModal={() => removePages(1)}
          />
        </FlexRow>

        {hasNoResults ? (
          <FlexCol
            alignItems="center"
            padding={32}
            gap={16}>
            <Illustration
              size="100px"
              illustration="noResults"
            />
            <P
              color="currentColor"
              textAlign="center">
              {t('workplace:CouldNotFindWorkspace')}
            </P>
          </FlexCol>
        ) : (
          <FlexCol gap={32}>
            {allData
              .filter(({data}) => {
                if (data.length) return true;
                return false;
              })
              .map((section) => (
                <FlexCol
                  gap={8}
                  key={section.key}>
                  {section.key === 'Unavailable' && (
                    <P
                      fontWeight="bold"
                      color="currentColor">
                      {t('workplace:WorkspaceGroupUnavailable')}
                    </P>
                  )}
                  {section.key === 'AdHoc' && (
                    <P
                      fontWeight="bold"
                      color="currenColor">
                      {t('AdHocWorkspaces')}
                    </P>
                  )}
                  <Div>
                    {section.data.map((workspace, index) => (
                      <WorkspaceSelectionCell
                        date={date}
                        workspace={workspace}
                        inviteeCount={inviteeCount}
                        key={`workspace-${index}`}
                      />
                    ))}
                  </Div>
                </FlexCol>
              ))}
            <Subtitle fontSize="sm">{t('workplace:NoWorkplaceInTheList')}</Subtitle>
          </FlexCol>
        )}
      </FlexCol>
    </ModalPage>
  );
};
