import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {NavLink, useLocation} from 'react-router-dom';
import {getIsHybridMeetingsFeatureEnabled, getIsOccupancyEnabled} from '@lib/store';

import {useTheme as useAppTheme, useAdminPermission, useBreakPoint, useFeatureToggle, useSidePanel} from '@hooks';
import {
  useAppSelector,
  getPendingIncomingConnectionCount,
  getUnreadNotifications,
  getUser,
  getUserInitials,
} from '@lib/store';

import {StyledNavLink, StyledNavAvatar} from './styles';
import {Icon, MapiqLogo} from '@atoms';
import {Badge, Button} from '@molecules';
import {cn} from '@utils';
import {Notifications} from '@pages';

const iconSize = '1.5625rem';

export const Navigation_ExperienceImprovements = ({footer}: {footer: boolean}) => {
  const bp = useBreakPoint();
  const enabledFeatures = useFeatureToggle();
  const {openSidePanel, setSidePanelContent} = useSidePanel();

  const {hasAdminPermission} = useAdminPermission();
  const location = useLocation();
  const {pathname} = location;
  const theme = useTheme();
  const {theme: appTheme} = useAppTheme();
  const {t} = useTranslation();

  const user = useAppSelector(getUser);
  const userInitials = useAppSelector(getUserInitials);
  const hasIncomingNotifications = !!useAppSelector(getUnreadNotifications).length;

  const showProfileButton = bp !== 'small';

  const getAvatar = () => {
    if (enabledFeatures.CustomerBranding) {
      return (
        <StyledNavAvatar
          $active={pathname.includes('profile')}
          size="medium"
          user={{...user, initials: userInitials}}
          shouldShowCheckIn
        />
      );
    }

    return user.imageHash ? (
      <StyledNavAvatar
        $active={pathname.includes('profile')}
        size="medium"
        user={{...user, initials: userInitials}}
      />
    ) : (
      <Icon
        color={pathname.includes('profile') ? theme.icon.active : theme.icon.inactive}
        icon="profile"
        size={iconSize}
      />
    );
  };

  return footer ? (
    <div className="flex py-4 gap-4 justify-between items-center w-full">
      <MainLinks />
    </div>
  ) : (
    <div className="grid grid-cols-[minmax(105px,1fr)_4fr_minmax(105px,1fr)] gap-4 justify-between items-center w-full">
      <div className="flex">
        <NavLink
          to="/"
          aria-label={t('GoHome')}>
          <MapiqLogo
            data-testid="organisms-header-logo"
            theme={appTheme === 'light' ? 'dark' : 'light'}
            type="full"
            width="105px"
          />
        </NavLink>
      </div>
      <div className="flex gap-3 md:gap-8 lg:gap-12 justify-center w-full">
        <MainLinks activeClassName="bg-beige-500*" />
      </div>

      <div className="flex justify-end items-center gap-2">
        <button
          className={'flex text-mapiq-black-800* relative shrink-0'}
          aria-label={t('screen:Notifications')}
          data-testid="organisms-navigation-notifications-button"
          onClick={
            bp !== 'small'
              ? (e) => {
                  e.stopPropagation();
                  e.preventDefault;
                  setSidePanelContent(<Notifications />, {
                    eventData: {pageId: 'notifications'},
                  });
                  openSidePanel();
                }
              : undefined
          }>
          {hasIncomingNotifications && (
            <Badge
              badge="notification"
              right="0"
              size="small"
              top="0"
            />
          )}
          <Icon
            color={'currentColor'}
            icon="bell"
            className="size-6 shrink-0"
          />
        </button>

        {showProfileButton && (
          <StyledNavLink
            $footer={footer}
            aria-label={t('screen:Profile')}
            data-testid="organisms-navigation-profile"
            to="profile">
            {getAvatar()}
          </StyledNavLink>
        )}
        {hasAdminPermission && !footer && (
          <Button
            size="small"
            as="a"
            /** @ts-expect-error */
            href={import.meta.env.MAPIQ_ADMIN_BASE_URL}
            target="_blank"
            rel="noopener noreferrer"
            button="secondary">
            {t('Admin')}
          </Button>
        )}
      </div>
    </div>
  );
};

const MainLinks = ({activeClassName}: {activeClassName?: string}) => {
  const {t} = useTranslation();
  const isHybridMeetingsFeatureEnabled = useAppSelector(getIsHybridMeetingsFeatureEnabled);
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const hasIncomingConnections = !!useAppSelector(getPendingIncomingConnectionCount);

  const hereAndNowTabToTarget = isHybridMeetingsFeatureEnabled || isOccupancyEnabled ? 'rooms' : 'explore';

  return (
    <>
      <NavLink
        className={({isActive}) =>
          cn(
            'text-mapiq-black-500 flex gap-1 md:gap-2 items-center justify-center p-2 rounded-lg transition-colors  aspect-square md:py-2 md:px-3 md:aspect-auto no-underline shrink-0 flex-col md:flex-row text-sm md:text-[15px]',
            {
              [`text-mapiq-black-800* ${activeClassName}`]: isActive,
            },
          )
        }
        aria-label={t('translation:tabs.Home')}
        data-testid="organisms-navigation-home"
        to="/">
        <Icon
          className="size-6 shrink-0"
          color={'currentColor'}
          icon="home"
        />
        <span className="sm:hidden md:inline leading-none translate-y-0.5">{t('translation:tabs.Home')}</span>
      </NavLink>

      <NavLink
        className={({isActive}) =>
          cn(
            'text-mapiq-black-500 flex gap-1 md:gap-2 items-center justify-center p-2 rounded-lg transition-colors  aspect-square md:py-2 md:px-3 md:aspect-auto no-underline shrink-0 flex-col md:flex-row text-sm md:text-[15px]',
            {
              [`text-mapiq-black-800* ${activeClassName}`]: isActive,
            },
          )
        }
        aria-label={t('translation:tabs.Calendar')}
        data-testid="organisms-navigation-calendar"
        to="/calendar">
        <Icon
          className="size-6 shrink-0"
          color={'currentColor'}
          icon="calendar"
        />
        <span className="sm:hidden md:inline leading-none translate-y-0.5">{t('translation:tabs.Calendar')}</span>
      </NavLink>

      <NavLink
        className={({isActive}) =>
          cn(
            'text-mapiq-black-500 flex gap-1 md:gap-2 items-center justify-center p-2 rounded-lg transition-colors  aspect-square md:py-2 md:px-3 md:aspect-auto no-underline shrink-0 flex-col md:flex-row text-sm md:text-[15px]',
            {
              [`text-mapiq-black-800* ${activeClassName}`]: isActive,
            },
          )
        }
        aria-label={t('translation:tabs.H&N')}
        data-testid="organisms-navigation-now"
        to={`now/${hereAndNowTabToTarget}`}>
        <Icon
          className="size-6 shrink-0"
          color={'currentColor'}
          icon="location"
        />
        <span className="sm:hidden md:inline leading-none translate-y-0.5">{t('translation:tabs.H&N')}</span>
      </NavLink>

      <NavLink
        className={({isActive}) =>
          cn(
            'text-mapiq-black-500 flex gap-1 md:gap-2 items-center justify-center p-2 rounded-lg transition-colors  aspect-square md:py-2 md:px-3 md:aspect-auto no-underline shrink-0 flex-col md:flex-row text-sm md:text-[15px]',
            {
              [`text-mapiq-black-800* ${activeClassName}`]: isActive,
            },
          )
        }
        aria-label={t('translation:tabs.Connections')}
        data-testid="organisms-navigation-connections"
        to={`/connections`}>
        <span className="relative">
          {hasIncomingConnections && (
            <Badge
              badge="notification"
              right="0"
              size="small"
              top="0"
            />
          )}
          <Icon
            className="size-6 shrink-0"
            color={'currentColor'}
            icon="users"
          />
        </span>
        <span className="sm:hidden md:inline leading-none translate-y-0.5">{t('translation:tabs.Connections')}</span>
      </NavLink>
    </>
  );
};
