import {useMemo} from 'react';
import {CalendarDayOverviewCardProps} from './types';
import {useTranslation} from 'react-i18next';

import {CalendarEventsList, ConnectionOverview, CreateEventCard} from '@organisms';
import {useFeatureToggle, useModal} from '@hooks';
import {TileItem} from './components/TileItem';
import {AvatarStack, Button} from '@molecules';
import {
  getIsMeetingsCalendarEnabled,
  getPendingWorkspaceReservationsByDate,
  getWorkspaceReservationByDate,
  useAppSelector,
  getHasUserGivenCalendarConsent,
  getIsUserCalendarConsentInvalid,
  getWorkdayByDate,
  useRelevantConnections,
} from '@lib/store';
import {StyledCard} from './styles';
import {useTiles} from './hooks/useTiles';
import {parseISO, startOfToday} from 'date-fns';
import {WorkdayTile} from './components/tiles/WorkdayTile';
import {WorkspaceTile} from './components/tiles/WorkspaceTile';
import {ParkingTile} from './components/tiles/ParkingTile';
import {ExtrasTile} from './components/tiles/ExtrasTile';
import {GroupBookingTile} from './components/tiles/GroupBookingTile/GroupBookingTile';
import {Icon} from '@atoms';
import {ConnectionsListModalPage} from './components/ConnectionsListModalPage';
import {isBeforeToday} from '@lib/utils';
import {NoOneThere} from '../../home/Connections/EmptyState';

export const CalendarDayOverview_ExperienceImprovements = ({className, date}: CalendarDayOverviewCardProps) => {
  const {t} = useTranslation();
  const {HybridMeetingsCalendarView} = useFeatureToggle();
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);

  const {addPages, openModal} = useModal();

  const {tiles, lastActiveIndex} = useTiles({date});

  const parsedDate = useMemo(() => parseISO(date), [date]);

  const {connections} = useRelevantConnections(date);

  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parsedDate));

  const pendingWorkspaceReservations = useAppSelector((state) =>
    getPendingWorkspaceReservationsByDate(state, parsedDate),
  );

  const isPast = isBeforeToday(parsedDate, startOfToday());
  const isCalendarEnabledByAdmin = useAppSelector(getIsMeetingsCalendarEnabled);
  const isCalendarConsentGiven = useAppSelector(getHasUserGivenCalendarConsent);
  const isCalendarConsentInvalid = useAppSelector(getIsUserCalendarConsentInvalid);

  const shouldShowMeetingCreationButton = isPast // Remove the button for the days in the past
    ? false
    : HybridMeetingsCalendarView && isCalendarEnabledByAdmin && isCalendarConsentGiven && !isCalendarConsentInvalid;

  const showMeetingsSection = HybridMeetingsCalendarView && isMeetingCalendarEnabled;

  return (
    <StyledCard className={className}>
      <div className="grid grid-cols-[auto_1fr_auto]">
        {tiles.map(({tile, active}, index) => {
          switch (tile) {
            case 'WorkdayTile':
              return (
                <WorkdayTile
                  key={tile + index}
                  date={date}
                  active={active}
                  isLastActive={active && index === lastActiveIndex}
                />
              );
            case 'WorkspaceTile':
              return (
                <WorkspaceTile
                  key={tile + index}
                  date={date}
                  active={active}
                  isLastActive={active && index === lastActiveIndex}
                />
              );
            case 'ParkingTile':
              return (
                workday && (
                  <ParkingTile
                    key={tile + index}
                    date={date}
                    buildingId={workday.nodeId}
                    active={active}
                    isLastActive={active && index === lastActiveIndex}
                  />
                )
              );
            case 'ExtrasTile':
              return (
                <ExtrasTile
                  key={tile + index}
                  date={date}
                />
              );
            case 'GroupBookingTile':
              // useTiles only returns one instance of GroupBookingTile
              // lastActiveIndex also take the lenght of the pendingWorkspaceReservations into account
              return (pendingWorkspaceReservations || []).map((wr, groupIndex) => (
                <GroupBookingTile
                  workspaceReservation={wr}
                  showMapPreview={pendingWorkspaceReservations?.length === 1 && !workspaceReservation}
                  key={tile + index}
                  date={date}
                  active={active}
                  isLastActive={active && index + groupIndex === lastActiveIndex}
                />
              ));

            default:
              return null;
          }
        })}
      </div>

      <div className="grid grid-cols-[auto_1fr_auto]">
        <h3 className="col-span-full">{t('connection:AtTheOffice')}</h3>
        {!!connections?.length ? (
          showMeetingsSection ? (
            <TileItem
              className="bg-beige-200 [&>*:first-child]:self-center [&>*:last-child]:self-center"
              onClick={() => {
                addPages([<ConnectionsListModalPage date={date} />]);
                openModal();
              }}>
              <span className="col-span-2">
                <AvatarStack
                  date={date}
                  overlap
                  users={connections?.map(({user}) => user) || []}
                />
              </span>
              <Icon
                icon="caretRight"
                color="currentColor"
                className="text-mapiq-black-800* size-5"
              />
            </TileItem>
          ) : (
            <div className="col-span-full mt-4">
              <ConnectionOverview
                dateKey={date}
                hideTitle
              />
            </div>
          )
        ) : (
          <span className="col-span-full mt-4">
            <NoOneThere />
          </span>
        )}
      </div>

      {showMeetingsSection && (
        <div className="grid grid-cols-[auto_1fr_auto]">
          <span className="flex justify-between items-center col-span-full">
            <h3 className="col-span-full">{t('meeting:YourSchedule')}</h3>
            {shouldShowMeetingCreationButton && (
              <Button
                onClick={() => {
                  addPages([<CreateEventCard date={date} />]);
                  openModal();
                }}
                button="tertiary">
                <Icon
                  icon="plus"
                  className="size-4"
                />
                {t('meeting:AddEvent')}
              </Button>
            )}
          </span>

          <div className="col-span-full mt-4">
            <CalendarEventsList
              date={date}
              hideCreationButton
            />
          </div>
        </div>
      )}
    </StyledCard>
  );
};
