import {useFeatureToggle} from '@hooks';
import {
  getIsParkingFeatureVisible,
  getParkingReservationByDate,
  getPendingWorkspaceReservationsByDate,
  getWorkdayByDate,
  getWorkspaceReservationByDate,
  useAppSelector,
} from '@lib/store';
import {parseISO} from 'date-fns';
import {useMemo} from 'react';

export const useTiles = ({date}: {date: string}) => {
  const parsedDate = useMemo(() => parseISO(date), [date]);

  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);

  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parsedDate));
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));

  const {ExtrasFeature: isExtrasFeatureEnabled} = useFeatureToggle();

  const pendingWorkspaceReservations = useAppSelector((state) =>
    getPendingWorkspaceReservationsByDate(state, parsedDate),
  );

  const tiles = useMemo(() => {
    let tilesArr = [
      {
        tile: 'WorkdayTile',
        active: true,
      },
    ];

    if (workday?.status === 'OfficeDay') {
      tilesArr.push({
        tile: 'WorkspaceTile',
        active: !!workspaceReservation,
      });
    }

    if (pendingWorkspaceReservations?.length) {
      tilesArr.push({
        tile: 'GroupBookingTile',
        active: true,
      });
    }

    if (workday?.status === 'OfficeDay') {
      if (isParkingFeatureVisible) {
        tilesArr.push({
          tile: 'ParkingTile',
          active: !!parkingReservation,
        });
      }

      if (isExtrasFeatureEnabled) {
        tilesArr.push({
          tile: 'ExtrasTile',
          active: false,
        });
      }
    }

    return tilesArr.sort((a, b) => +b.active - +a.active);
  }, [
    pendingWorkspaceReservations,
    workday?.status,
    workspaceReservation,
    isParkingFeatureVisible,
    isExtrasFeatureEnabled,
    parkingReservation,
  ]);

  // `tiles` only returns one instance of GroupBookingTile
  // `groupBookingOffset` will be used to give an offset to the `lastActiveIndex`
  const groupBookingOffset = (pendingWorkspaceReservations?.length || 1) - 1;
  const lastActiveIndex = tiles.map(({active}) => active).lastIndexOf(true) + groupBookingOffset;

  return {
    tiles,
    lastActiveIndex,
  };
};
