import {ChangeEvent, HTMLAttributes, useState} from 'react';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {useTranslation} from 'react-i18next';
import {StyledModalPage} from './styles';
import {Input, ModalCell} from '@molecules';
import {FlexCol} from '@quarks';
import {ModalPageBottom, ParkingSelectionCard} from '@organisms';
import {getLicensePlates, updateLicensePlates, withAsyncThunkErrorHandling} from '@lib/store';
import {ValidationCard} from '@atoms';
import toast from 'react-hot-toast';

export type Props = HTMLAttributes<HTMLDivElement> & {
  buildingId?: string;
  date?: string;
  passedLicensePlate?: string;
};

export const LicensePlatesFormModalPage = ({buildingId, date, passedLicensePlate = ''}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {closeModal, removePages, addPages} = useModal();
  const licensePlates = useAppSelector(getLicensePlates);

  const [licensePlate, setLicensePlate] = useState(passedLicensePlate);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const shouldNavigateToParkingSelection = !!date && buildingId;

  const isDuplicate = licensePlates.filter((p) => p !== passedLicensePlate).indexOf(licensePlate) !== -1;
  // contains any of special characters and is between 1 and 20 characters.
  const licensePlatePattern = /^[^'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]{1,20}$/;
  const canSave = licensePlatePattern.test(licensePlate) && !isDuplicate;

  const validationMessage = error?.message
    ? error.message
    : isDuplicate
    ? t('profile:LicensePlate.userValidationIsDuplicate')
    : null;

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);
    const replacePattern = /['`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]+/;
    const value = e.target.value ? e.target.value.replace(replacePattern, '') : '';

    setLicensePlate((cur) => {
      if (value.length < cur.length || licensePlatePattern.test(e.target.value)) {
        return value.toUpperCase();
      }
      return cur;
    });
  };

  const handleSave = async () => {
    setIsLoading(true);

    const next = [...licensePlates];
    if (!passedLicensePlate) {
      next.push(licensePlate);
    } else {
      const ix = next.indexOf(passedLicensePlate);
      if (ix !== -1) {
        next[ix] = licensePlate;
      }
    }

    const res = await dispatch(withAsyncThunkErrorHandling(() => updateLicensePlates(next)));
    setIsLoading(false);

    if (res.success) {
      if (shouldNavigateToParkingSelection) {
        toast.success(t('profile:LicensePlate.succesToastMessage'));
        addPages([
          <ParkingSelectionCard
            date={date}
            buildingId={buildingId}
          />,
        ]);
      } else {
        removePages(1);
      }
    } else {
      setError(res.result);
    }
  };

  return (
    <StyledModalPage
      bottom={
        <ModalPageBottom
          buttonLabel={shouldNavigateToParkingSelection ? t('translation:Confirm') : t('translation:Save')}
          buttonDisabled={!canSave}
          buttonLoading={isLoading}
          onClick={() => handleSave()}
        />
      }
      onBack={() => removePages(1)}
      title={t('profile:LicensePlate.addLicensePlate')}
      onClose={closeModal}>
      <ModalCell>
        <FlexCol
          rowGap={16}
          alignItems="center"
          justifyContent="center">
          <Input
            size="full"
            invalid={isDuplicate}
            value={licensePlate}
            placeholder={'32PXTT'}
            onChange={handleInput}
            type="text"
          />

          {validationMessage && <ValidationCard>{validationMessage}</ValidationCard>}
        </FlexCol>
      </ModalCell>
    </StyledModalPage>
  );
};
