import {CalendarParkingTileProps} from './types';

import {
  getIsUserAllowedToReserveParking,
  getLicensePlates,
  getParkingReservationByDate,
  getWorkdayByDate,
  getParkingSettings,
} from '@lib/store';
import {useAppSelector, useFeatureToggle, useModal} from '@hooks';
import {parseISO} from 'date-fns';

import {SquareButton} from '@molecules';
import {CalendarParkingTileBookingInfo, ParkingSelectionCard} from '@organisms';
import {LicensePlatesModalPage} from '../../profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';

export const CalendarParkingTile = ({date}: CalendarParkingTileProps) => {
  const {Parking: isFeatureEnabled} = useFeatureToggle();
  const {openModal, setModalPages} = useModal();

  const licensePlates = useAppSelector(getLicensePlates);

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const parkingSettings = useAppSelector(getParkingSettings);

  const {nodeId: buildingId} = useAppSelector((state) => getWorkdayByDate(state, date)) || {};
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    buildingId ? getIsUserAllowedToReserveParking(state, parseISO(date), buildingId) : false,
  );

  if (!isFeatureEnabled) return <></>;

  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;
  const isDisabled = !isUserAllowedToReserveParking && !parkingReservation;

  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;

  const handleOnClick = () => {
    if (isDisabled) return;

    if (!buildingId) throw new Error('buildingId is required');
    if (shouldOpenLicensePlateModal) {
      setModalPages([
        <LicensePlatesModalPage
          date={date}
          buildingId={buildingId}
        />,
      ]);
    } else {
      setModalPages([
        <ParkingSelectionCard
          date={date}
          buildingId={buildingId}
        />,
      ]);
    }

    openModal();
  };

  return (
    <SquareButton
      iconLeft="car"
      iconRight="caretRight"
      squareButton="energizingYellow"
      onClick={handleOnClick}
      disabled={isDisabled}>
      <CalendarParkingTileBookingInfo
        date={date}
        // TODO: NOT SURE IF THIS WILL BREAK ANYTHING
        buildingId={buildingId || ''}
      />
    </SquareButton>
  );
};
