import {
  useAppSelector,
  getIsUserAllowedToReserveParking,
  getLicensePlates,
  getParkingSettings,
  getParkingReservationByDate,
} from '@lib/store';
import {useModal} from '@hooks';

import {CalendarParkingTileBookingInfo, ParkingSelectionCard} from '@organisms';
import {ComponentProps, useMemo} from 'react';
import {Icon} from '@atoms';
import {parseISO} from 'date-fns';
import {TileItem} from '../../TileItem';
import {LicensePlatesModalPage} from 'src/components/organisms/profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';

export const ParkingTile = ({
  date,
  buildingId,
  ...props
}: ComponentProps<typeof TileItem> & {date: string; buildingId: string}) => {
  const {addPages, openModal} = useModal();

  const dateAsDate = useMemo(() => parseISO(date), [date]);
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));

  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, dateAsDate, buildingId),
  );

  const parkingSettings = useAppSelector(getParkingSettings);

  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;
  const licensePlates = useAppSelector(getLicensePlates);
  const isDisabled = !isUserAllowedToReserveParking && !parkingReservation;

  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;

  return (
    <TileItem
      disabled={isDisabled}
      data-testid="workday-tile"
      onClick={() => {
        if (isDisabled) return;

        if (shouldOpenLicensePlateModal) {
          addPages([
            <LicensePlatesModalPage
              date={date}
              buildingId={buildingId}
            />,
          ]);
        } else {
          addPages([
            <ParkingSelectionCard
              date={date}
              buildingId={buildingId}
            />,
          ]);
        }

        openModal();
      }}
      {...props}>
      <Icon
        className="size-5"
        icon={'car'}
      />
      <div>
        <CalendarParkingTileBookingInfo
          date={date}
          buildingId={buildingId}
        />
      </div>

      {props.active ? (
        <span className="size-8 bg-connecting-green-500* rounded-md flex justify-center items-center">
          <Icon
            className="size-5 [&>path]:stroke-2"
            color="white"
            icon={'check'}
          />
        </span>
      ) : (
        <Icon
          className="size-5 justify-self-end"
          icon={'plus'}
        />
      )}
    </TileItem>
  );
};
