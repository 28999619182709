import {Icon} from '@atoms';
import {DeskAmenity} from '@lib/infrastructure';
import {Button} from '@molecules';
import {getDeskAmenityIcon} from '@utils';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  deskAmenities: string[] | DeskAmenity[];
  limit?: number;
}

export const DeskAmenitiesList = ({deskAmenities, limit = 3}: Props) => {
  const {t} = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const items = isExpanded ? deskAmenities : deskAmenities.slice(0, limit);

  return (
    <>
      <ul className="flex flex-col gap-2">
        {items.map((deskAmenity) => (
          <li
            key={deskAmenity}
            className="flex items-center gap-2">
            <Icon
              icon={getDeskAmenityIcon(deskAmenity)}
              size="1rem"
            />
            <p>{t('deskAmenities:deskAmenity', {context: deskAmenity as DeskAmenity})}</p>
          </li>
        ))}
      </ul>

      {deskAmenities.length > limit && (
        <Button
          button="text"
          className="mt-3"
          onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('translation:ShowLess') : t('translation:ShowMore')}
        </Button>
      )}
    </>
  );
};
