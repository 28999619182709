import {useMemoArray} from '@lib/utils';
import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

type ResponseType = {removeURLParams: () => void};

export const useQueryParams = <K extends string>(keys: K[]): Record<K, string | null> & ResponseType => {
  const memoizedKeys = useMemoArray(keys);
  const [searchParams, setSearchParams] = useSearchParams();

  const values = useMemo(() => {
    const result: Partial<Record<K, string | null>> = {};

    memoizedKeys.forEach((key) => {
      result[key] = searchParams.get(key);
    });

    return result as Record<K, string | null>;
  }, [memoizedKeys, searchParams]);

  const removeURLParams = useCallback(() => {
    memoizedKeys.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams, {replace: true});
  }, [memoizedKeys, searchParams, setSearchParams]);

  return {...values, removeURLParams};
};
