import {BREAKPOINTS} from '@constants';
import {css} from 'styled-components';

// @mixin darkmode {
//   &[data-theme='dark'] {
//     @content;
//   }
// }

const darkmode = `[data-theme='dark']`;

export const Variables = css`
  :root {
    /**
    * * DATE TIME PICKER
    */
    --dtp-color: ${({theme}) => theme.text.color.body};
    --dtp-background-input-background: ${({theme}) => theme.card.beige200.background};
    --dtp-focus-visible: ${({theme}) => theme.brand};
    --dtp-background-picker-background: ${({theme}) => theme.background.sand};
    --dtp-background-weekday-names: ${({theme}) => theme.text.color.subtitle};
    --dtp-background-box-shadow: ${({theme}) => theme.boxShadow.secondary};
    --dtp-background-disabled: ${({theme}) => theme.disabled.color};
    --dtp-background-day-hover: ${({theme}) => theme.card.white.hover.background};
    --dtp-background-today: ${({theme}) => theme.link.default};
    --dtp-background-today-hover: ${({theme}) => theme.link.hover};
    --dtp-color-selected: ${({theme}) => theme.card.connectingGreen.color};
    --dtp-color-selected-hover: ${({theme}) => theme.card.connectingGreen.hover.color};
    --dtp-background-selected: ${({theme}) => theme.card.connectingGreen.background};
    --dtp-background-selected-hover: ${({theme}) => theme.card.connectingGreen.hover.background};
    --dtp-color-kbd-select: ${({theme}) => theme.card.white.color};
    --dtp-background-kbd-select-hover: ${({theme}) => theme.card.white.background};
    --dtp-background-kbd-select: ${({theme}) => theme.card.white.hover.background};

    /**
  * * BORDER-RADII
  */

    --border-radius-0: 0px;
    --border-radius-2: calc(2 / 16 * 1rem);
    --border-radius-4: calc(4 / 16 * 1rem);
    --border-radius-8: calc(8 / 16 * 1rem);
    --border-radius-10: calc(10 / 16 * 1rem);
    --border-radius-12: calc(12 / 16 * 1rem);
    --border-radius-9999: calc(9999 / 16 * 1rem);

    /**
  * * BOX-SHADOWS
  */
    --box-shadow-1-left: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-1-top: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-1-bottom: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-2-left: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-2-top: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-2-bottom: ${({theme}) => theme.boxShadow.secondary};
    --box-shadow-floating-window: ${({theme}) => theme.boxShadow.secondary};

    --box-shadow-1-left: 2px 2px 0 0 chartreuse;
    --box-shadow-1-top: 2px 2px 0 0 chartreuse;
    --box-shadow-1-bottom: 2px 2px 0 0 chartreuse;
    --box-shadow-2-left: 2px 2px 0 0 chartreuse;
    --box-shadow-2-top: 2px 2px 0 0 chartreuse;
    --box-shadow-2-bottom: 2px 2px 0 0 chartreuse;
    --box-shadow-floating-window: 2px 2px 0 0 chartreuse;

    /**
  * * COLORS
  */
    // PRIMARY
    --color-brand: chartreuse;

    --color-white: chartreuse;
    --color-white-a03: chartreuse;
    --color-white-a06: chartreuse;
    --color-white-a10: chartreuse;
    --color-white-a15: chartreuse;
    --color-white-a20: chartreuse;
    --color-white-a30: chartreuse;
    --color-white-a40: chartreuse;
    --color-white-a50: chartreuse;
    --color-white-a60: chartreuse;
    --color-white-a70: chartreuse;
    --color-white-a80: chartreuse;
    --color-white-a90: chartreuse;
    --color-white-a100: chartreuse;

    --color-black: #000;
    --color-black-a03: chartreuse;
    --color-black-a06: chartreuse;
    --color-black-a10: chartreuse;
    --color-black-a15: chartreuse;
    --color-black-a20: chartreuse;
    --color-black-a30: chartreuse;
    --color-black-a40: chartreuse;
    --color-black-a50: chartreuse;
    --color-black-a60: chartreuse;
    --color-black-a70: chartreuse;
    --color-black-a80: chartreuse;
    --color-black-a90: chartreuse;
    --color-black-a100: chartreuse;

    // BLUE
    --color-blue-900: chartreuse;
    --color-blue-800: chartreuse;
    --color-blue-700: chartreuse;
    --color-blue-600: chartreuse;
    --color-blue-500: chartreuse;
    --color-blue-400: chartreuse;
    --color-blue-300: chartreuse;
    --color-blue-200: chartreuse;
    --color-blue-100: chartreuse;
    --color-blue-50: chartreuse;
    --color-blue-25: chartreuse;

    // GREEN
    --color-green-900: chartreuse;
    --color-green-800: chartreuse;
    --color-green-700: chartreuse;
    --color-green-600: chartreuse;
    --color-green-500: chartreuse;
    --color-green-400: chartreuse;
    --color-green-300: chartreuse;
    --color-green-200: chartreuse;
    --color-green-100: chartreuse;
    --color-green-50: chartreuse;

    // GREY
    --color-grey-900: chartreuse;
    --color-grey-800: chartreuse;
    --color-grey-700: chartreuse;
    --color-grey-600: chartreuse;
    --color-grey-500: chartreuse;
    --color-grey-400: chartreuse;
    --color-grey-300: chartreuse;
    --color-grey-200: chartreuse;
    --color-grey-100: chartreuse;
    --color-grey-50: chartreuse;
    --color-grey-25: chartreuse;

    --color-gray-900: chartreuse;
    --color-gray-800: chartreuse;
    --color-gray-700: chartreuse;
    --color-gray-600: chartreuse;
    --color-gray-500: chartreuse;
    --color-gray-400: chartreuse;
    --color-gray-300: chartreuse;
    --color-gray-200: chartreuse;
    --color-gray-100: chartreuse;
    --color-gray-50: chartreuse;
    --color-gray-25: chartreuse;

    // ORANGE
    --color-orange-900: chartreuse;
    --color-orange-800: chartreuse;
    --color-orange-700: chartreuse;
    --color-orange-600: chartreuse;
    --color-orange-500: chartreuse;
    --color-orange-400: chartreuse;
    --color-orange-300: chartreuse;
    --color-orange-200: chartreuse;
    --color-orange-100: chartreuse;
    --color-orange-50: chartreuse;

    // PURPLE
    --color-purple-900: chartreuse;
    --color-purple-800: chartreuse;
    --color-purple-700: chartreuse;
    --color-purple-600: chartreuse;
    --color-purple-500: chartreuse;
    --color-purple-400: chartreuse;
    --color-purple-300: chartreuse;
    --color-purple-200: chartreuse;
    --color-purple-100: chartreuse;
    --color-purple-50: chartreuse;

    // RED
    --color-red-900: chartreuse;
    --color-red-800: chartreuse;
    --color-red-700: chartreuse;
    --color-red-600: chartreuse;
    --color-red-500: chartreuse;
    --color-red-400: chartreuse;
    --color-red-300: chartreuse;
    --color-red-200: chartreuse;
    --color-red-100: chartreuse;
    --color-red-50: chartreuse;

    // SAND
    --color-sand-900: chartreuse;
    --color-sand-800: chartreuse;
    --color-sand-700: chartreuse;
    --color-sand-600: chartreuse;
    --color-sand-500: chartreuse;
    --color-sand-400: chartreuse;
    --color-sand-300: chartreuse;
    --color-sand-200: chartreuse;
    --color-sand-100: chartreuse;
    --color-sand-50: chartreuse;

    // VIOLET
    --color-violet-900: chartreuse;
    --color-violet-800: chartreuse;
    --color-violet-700: chartreuse;
    --color-violet-600: chartreuse;
    --color-violet-500: chartreuse;
    --color-violet-400: chartreuse;
    --color-violet-300: chartreuse;
    --color-violet-200: chartreuse;
    --color-violet-100: chartreuse;
    --color-violet-50: chartreuse;

    // YELLOW
    --color-yellow-900: chartreuse;
    --color-yellow-800: chartreuse;
    --color-yellow-700: chartreuse;
    --color-yellow-600: chartreuse;
    --color-yellow-500: chartreuse;
    --color-yellow-400: chartreuse;
    --color-yellow-300: chartreuse;
    --color-yellow-200: chartreuse;
    --color-yellow-100: chartreuse;
    --color-yellow-50: chartreuse;

    /**
  * * FONTS
  */
    --font-1: calc(1 / 16 * 1rem);
    --font-2: calc(2 / 16 * 1rem);
    --font-3: calc(3 / 16 * 1rem);
    --font-4: calc(4 / 16 * 1rem);
    --font-5: calc(5 / 16 * 1rem);
    --font-6: calc(6 / 16 * 1rem);
    --font-7: calc(7 / 16 * 1rem);
    --font-8: calc(8 / 16 * 1rem);
    --font-9: calc(9 / 16 * 1rem);
    --font-10: calc(10 / 16 * 1rem);
    --font-11: calc(11 / 16 * 1rem);
    --font-12: calc(12 / 16 * 1rem);
    --font-13: calc(13 / 16 * 1rem);
    --font-14: calc(14 / 16 * 1rem);
    --font-15: calc(15 / 16 * 1rem);
    --font-16: calc(16 / 16 * 1rem);
    --font-17: calc(17 / 16 * 1rem);
    --font-18: calc(18 / 16 * 1rem);
    --font-20: calc(20 / 16 * 1rem);
    --font-22: calc(22 / 16 * 1rem);
    --font-24: calc(24 / 16 * 1rem);
    --font-25: calc(25 / 16 * 1rem);
    --font-26: calc(26 / 16 * 1rem);
    --font-32: calc(32 / 16 * 1rem);
    --font-40: calc(40 / 16 * 1rem);

    /**
  * * PADDINGS
  */

    --padding-x: 40px;
    --padding-y: 32px;
    --gap-y: 24px;

    ${BREAKPOINTS.medium` {
      --padding-x: 56px;
      --padding-y: 48px;
      --gap-y: 40px;
    }`}

    ${BREAKPOINTS.small` {
      --padding-x: 32px;
      --padding-y: 24px;
      --gap-y: 16px;
    }`}

    /**
  * * SPEEDS
  */
    --speed-ultrafast: 0.05s;
    --speed-superfast: 0.1s;
    --speed-fast: 0.2s;
    --speed-normal: 0.5s;
    --speed-slow: 1s;
    --speed-superslow: 2s;
    --speed-ultraslow: 4s;

    @media (prefers-reduced-motion: reduce) {
      --speed-ultrafast: 0.1s;
      --speed-superfast: 0.2s;
      --speed-fast: 1s;
      --speed-normal: 2s;
      --speed-slow: 4s;
      --speed-superslow: 8s;
      --speed-ultraslow: 16s;
    }

    /**
  * * MAPIQ VARIABLES
  */
    // ! WIP

    // Measurements
    --header-height: clamp(64px, calc(64 / 16 * 1rem), 120px);

    // General
    --bg-color: var(--color-white);
    --bg-color-disabled: var(--color-grey-25);
    --color-accent: var(--color-red-200);
    --color-accent-hover: var(--color-red-300);
    --color-body: var(--color-sand-50);
    --color-disabled: var(--color-grey-200);
    --color-hr: var(--color-sand-300);

    --color-link: var(--color-blue-600);
    --color-link-hover: var(--color-blue-400);
    --color-nav-stroke: var(--color-black);
    --color-text: var(--color-black);
    --color: var(--color-black);
    --color-heading: var(--color-sand-900);
    --color-secondary: var(--color-grey-600);

    --color-success: var(--color-green-200);
    --color-success-hover: var(--color-green-300);
    --color-danger: var(--color-red-200);
    --bg-color-danger: var(--color-red-50);
    --bg-color-danger-hover: var(--color-red-100);
    --color-warning: var(--color-yellow-200);
    --color-info: var(--color-blue-25);

    --bg-color-modal-background: var(--color-black-a20);

    &${darkmode} {
      --color-text: var(--color-white);
      --color-success: var(--color-green-500);
      --color-success-hover: var(--color-green-600);
      --color-danger: var(--color-red-400);
      --bg-color-danger: var(--color-red-100);
      --bg-color-danger-hover: var(--color-red-200);
      --color-warning: var(--color-yellow-400);
      --color-info: var(--color-blue-200);

      --bg-color-modal-background: var(--color-white-a20);
    }

    --bg-color-login-card: var(--color-white-a80);

    // Avatar colors
    --avatar-yellow: var(--color-yellow-100);
    --avatar-orange: var(--color-orange-100);
    --avatar-red: var(--color-red-100);
    --avatar-green: var(--color-green-100);
    --avatar-greener: var(--color-green-200);
    --avatar-blue: var(--color-blue-50);
    --avatar-purple: var(--color-purple-100);
    --avatar-violet: var(--color-violet-100);
    --avatar-sand: var(--color-sand-100);
    --avatar-grey: var(--color-grey-50);

    --avatar-yellow-hover: var(--color-yellow-200);
    --avatar-orange-hover: var(--color-orange-200);
    --avatar-red-hover: var(--color-red-200);
    --avatar-green-hover: var(--color-green-200);
    --avatar-blue-hover: var(--color-blue-100);
    --avatar-purple-hover: var(--color-purple-200);
    --avatar-violet-hover: var(--color-violet-200);
    --avatar-sand-hover: var(--color-sand-200);
    --avatar-grey-hover: var(--color-grey-100);

    &${darkmode} {
      --avatar-yellow: var(--color-yellow-200);
      --avatar-orange: var(--color-orange-300);
      --avatar-red: var(--color-red-300);
      --avatar-green: var(--color-green-300);
      --avatar-greener: var(--color-green-700);
      --avatar-blue: var(--color-blue-300);
      --avatar-purple: var(--color-purple-300);
      --avatar-violet: var(--color-violet-300);
      --avatar-sand: var(--color-sand-600);
      --avatar-grey: var(--color-grey-300);

      --avatar-yellow-hover: var(--color-yellow-100);
      --avatar-orange-hover: var(--color-orange-200);
      --avatar-red-hover: var(--color-red-200);
      --avatar-green-hover: var(--color-green-200);
      --avatar-blue-hover: var(--color-blue-200);
      --avatar-purple-hover: var(--color-purple-200);
      --avatar-violet-hover: var(--color-violet-200);
      --avatar-sand-hover: var(--color-sand-500);
      --avatar-grey-hover: var(--color-grey-200);
    }

    // Button
    --color-button-border: transparent;
    --bg-color-button-primary: var(--color-sand-900);
    --bg-color-button-primary-hover: var(--color-black);
    --color-button-primary: var(--color-white);

    --bg-color-button-secondary: transparent;
    --color-button-secondary-border: var(--color-grey-900);
    --color-button-secondary-border-hover: var(--color-black);
    --color-button-secondary: var(--color-grey-900);
    --color-button-secondary-hover: var(--color-black);

    --bg-color-button-tertiary: var(--color-black-a03);
    --bg-color-button-tertiary-hover: var(--color-black-a06);
    --color-button-tertiary: var(--color-grey-900);
    --color-button-tertiary-hover: var(--color-black);

    // Badge
    --badge-green: var(--color-green-400);
    --badge-grey: var(--color-grey-200);
    --badge-orange: var(--color-orange-200);
    --badge-red: var(--color-red-200);

    &${darkmode} {
      --badge-green: var(--color-green-500);
      --badge-grey: var(--color-grey-300);
      --badge-orange: var(--color-orange-400);
      --badge-red: var(--color-red-300);
    }

    // Inputs
    --bg-color-input: var(--color-grey-25);
    --bg-color-toggle: var(--color-grey-200);
    --bg-color-listbox: var(--color-white);
    --bg-color-input-selection: var(--color-black);
    --color-input-selection: var(--color-white);
    --color-input-placeholder: var(--color-grey-600);
    --color-input-border: var(--color-green-800);

    &${darkmode} {
      --bg-color-input: var(--color-grey-900);
      --bg-color-toggle: var(--color-grey-800);
      --bg-color-listbox: var(--color-black);
      --bg-color-input-selection: var(--color-white);
      --color-input-selection: var(--color-black);
      --color-input-placeholder: var(--color-grey-600);
      --color-input-border: var(--color-green-800);
    }

    // Calendar
    --bg-color-calendar-card-cell: var(--bg-color);
    --bg-color-calendar-card-cell-past: var(--color-grey-25);
    --border-color-calendar-card-cell: var(--color-grey-50);
    --bg-color-today-tile: var(--color-black-a03);
    --bg-color-sync-calendar-tile: var(--color-green-200);
    --bg-color-sync-calendar-tile-error: var(--color-red-100);
    --bg-color-today-indicator: var(--color-black);
    --bg-color-today-tile-booked-shift: var(--color-blue-50);

    // Notifications
    --color-highlight: var(--color-blue-500);

    &${darkmode} {
      --bg-color-calendar-card-cell: #26262c;
      --bg-color-calendar-card-cell-past: #18181b;
      --border-color-calendar-card-cell: var(--color-grey-900);
      --bg-color-today-tile: var(--color-white-a06);
      --bg-color-sync-calendar-tile: var(--color-green-700);
      --bg-color-sync-calendar-tile-error: var(--color-red-900);
      --bg-color-today-indicator: var(--color-green-500);
      --bg-color-today-tile-booked-shift: var(--color-blue-600);
    }

    // Profile
    --bg-color-quota-card: var(--color-sand-50);

    &${darkmode} {
      --bg-color-quota-card: var(--color-body);
    }

    // Day Overview
    --border-color-day-overview-event-cell: var(--color-black-a10);
    --bg-color-tile-sand: var(--color-sand-50);
    --bg-color-tile-sand-hover: var(--color-sand-100);
    --bg-color-tile-blue: var(--color-blue-50);
    --bg-color-tile-blue-hover: var(--color-blue-100);
    --bg-color-tile-opaque: transparent;
    --bg-color-tile-opaque-hover: var(--color-black-a03);

    &${darkmode} {
      --border-color-day-overview-event-cell: var(--color-white-a10);
      --bg-color-tile-sand: var(--color-body);
      --bg-color-tile-sand-hover: var(--color-sand-900);
      --bg-color-tile-blue: var(--color-blue-800);
      --bg-color-tile-blue-hover: var(--color-blue-900);
      --bg-color-tile-opaque: transparent;
      --bg-color-tile-opaque-hover: var(--color-white-a03);
    }

    // Create event
    --border-color-create-button: var(--color-grey-50);
    --bg-color-suggestion-card: var(--color-grey-25);
    --border-color-suggestion-card: var(--color-grey-200);
    --bg-color-create-button-hover: var(--bg-color-tile-sand);
    --bg-color-suggestion-card-hover: var(--color-grey-100);
    --color-show-suggestions: var(--color-green-900);

    &${darkmode} {
      --border-color-create-button: var(--color-grey-700);
      --bg-color-suggestion-card: var(--color-grey-800);
      --border-color-suggestion-card: var(--color-grey-200);
      --bg-color-create-button-hover: var(--bg-color-tile-sand);
      --bg-color-suggestion-card-hover: var(--color-grey-900);
      --color-show-suggestions: var(--color-green-800);
    }

    /**
  * * DARKMODE
  */
    // ! WIP
    &${darkmode} {
      --color-info: var(--color-blue-400);
      // --bg-color: var(--color-grey-900);
      --bg-color: #27272a;
      --bg-color-disabled: var(--color-grey-800);
      --color-disabled: var(--color-grey-900);
      --color-accent-hover: var(--color-red-500);
      --color-link: var(--color-blue-300);
      --color-link-hover: var(--color-blue-200);
      --color-accent: var(--color-red-400);
      --color-background: var(--color-grey-800);
      // --color-body: var(--color-grey-800);
      --color-body: #323235; //#353235;
      --color-nav-stroke: var(--color-grey-700);
      --color-text: var(--color-white);
      --color: var(--color-white);
      --color-heading: var(--color-sand-50);

      --color-hr: var(--color-sand-800);

      --color-button-secondary-border: var(--color-grey-100);
      --color-button-secondary-border-hover: var(--color-white);
      --color-button-secondary: var(--color-grey-100);
      --color-button-secondary-hover: var(--color-white);
      --bg-color-button-tertiary: rgba(255, 255, 255, 0.06);
      --bg-color-button-tertiary-hover: rgba(255, 255, 255, 0.1);
      --color-button-tertiary: var(--color-white-a80);
      --color-button-tertiary-hover: var(--color-white-a90);

      --bg-color-login-card: var(--color-black-a80);
    }
    //
    //
    //
    //
    //
    //
    //
    --gap-color: var(--color-grey-50);
    --subtitle-color: var(--color-grey-800);

    &${darkmode} {
      --gap-color: var(--color-grey-900);
    }

    --duotone-color: var(--color-white);

    &${darkmode} {
      --duotone-color: var(--color-black);
    }

    --hover-filter: brightness(0.97);

    &${darkmode} {
      --hover-filter: brightness(1.07);
    }
  }
`;
