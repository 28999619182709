import {useModal} from '@hooks';
import {ModalPage, ModalPageBottom} from '@organisms';
import {Div, FlexCol, FlexRow, H3, Span} from '@quarks';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LearnMore, StyledIcon, StyledInputLabel} from './styles';
import {Avatar, Input} from '@molecules';
import {IconNames} from 'mapiq-atoms';
import {pxToRem} from '@utils';
import {
  useFilters,
  getIsMatchingDynamicSelections,
  getOptionsMatchingSearchParam,
  getShowMorePerSection,
  FilterOptionType,
  getSectionsAsEmptyDependingOn,
  generateFilterSectionType,
} from '@lib/logic';
import {FilterSliceKeyType, useDebounce, FilterConfigType, FilterOption} from '@lib/utils';

const MAX_ITEMS_IN_LIST = 3;

type Props = {
  filterSlice: FilterSliceKeyType;
  onCloseCallback?: () => void;
  onBackCallback?: () => void;
  showSearchBar?: boolean;
  searchBarPlaceholderText?: string;
  searchByProperties?: string[];
};

export const NewFilterCard = ({
  filterSlice,
  onBackCallback,
  onCloseCallback,
  showSearchBar,
  searchBarPlaceholderText,
  searchByProperties = [],
}: Props) => {
  const {t} = useTranslation();
  const {closeModal} = useModal();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce<string | undefined>(searchQuery, 300);
  const trimmedDebouncedSearchQuery = debouncedSearchQuery?.trim();

  const {selectedFilters, config: filterConfig, getInitFilterSelection, applyFilters} = useFilters(filterSlice);

  const handleShowMore = (sectionId: string) => {
    const showingMore = Boolean(showMorePerSection[sectionId]);

    setShowMorePerSection({
      ...showMorePerSection,
      [sectionId]: !showingMore,
    });
  };

  const [localFilters, setLocalFilters] = useState(selectedFilters);

  const handleResetFilters = () => {
    setSearchQuery('');
    setLocalFilters(getInitFilterSelection(filterConfig));
  };

  const handleSubmitFilters = () => {
    if (applyFilters(localFilters)) {
      onBackCallback ? onBackCallback() : closeModal();
    } else {
      throw new Error('One of your required filters does not have a value set.');
    }
  };

  const filterSections = useMemo(() => {
    return Object.values(filterConfig).reduce((acc, configItem) => {
      if (!configItem || configItem.hiddenSection) return acc;

      const options = configItem.data.filter((fo) =>
        getIsMatchingDynamicSelections(localFilters, fo.dynamicFilterConfig),
      );

      if (options.length === 0) return acc;

      return [
        ...acc,
        {
          ...configItem,
          data: !showSearchBar
            ? options
            : getOptionsMatchingSearchParam(options, searchByProperties, trimmedDebouncedSearchQuery),
        },
      ];
    }, [] as FilterConfigType<FilterOption>[]);
  }, [filterConfig, localFilters, searchByProperties, showSearchBar, trimmedDebouncedSearchQuery]);

  const [showMorePerSection, setShowMorePerSection] = useState(getShowMorePerSection(filterSections, localFilters));

  const filterSectionList = useMemo(
    () =>
      filterSections.map((section) => {
        if (section.dontCollaplseOptionsAboveThreshold || showMorePerSection[section.sectionId]) {
          return section;
        }

        return {...section, data: section.data.slice(0, MAX_ITEMS_IN_LIST)};
      }),
    [filterSections, showMorePerSection],
  );

  function handleOnChange({sectionId, type, id}: {sectionId: string; type: FilterOptionType; id: string}) {
    const selectedItems = localFilters[sectionId] ?? [];
    const isAlreadySelected = !!selectedItems.find((si) => si === id);

    // we cant deselect mandatory filters
    if (isAlreadySelected && type === 'singleMandatory') return;

    // pressing on selected item will deselect it
    if (isAlreadySelected) {
      setLocalFilters({...localFilters, [sectionId]: selectedItems.filter((si) => si !== id)});
      return;
    }

    // we should just select the item if its multiselect
    if (type === 'and' || type === 'or') {
      setLocalFilters({...localFilters, [sectionId]: [...selectedItems, id]});
      return;
    }

    const currentlySelected = selectedItems[0] as string | undefined;
    // we should also deselect any options from other sections
    // that were conditionally shown based on currently selected option
    if (currentlySelected) {
      const dependantEmptySections = getSectionsAsEmptyDependingOn(filterConfig, sectionId, currentlySelected);
      setLocalFilters({...localFilters, [sectionId]: [id], ...dependantEmptySections});
      return;
    }

    setLocalFilters({...localFilters, [sectionId]: [id]});
  }

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          buttonLabel={t('translation:ApplyFilter')}
          cancelButtonLabel={t('translation:ClearFilter')}
          onCancel={handleResetFilters}
          onClick={handleSubmitFilters}
        />
      }
      onClose={onCloseCallback ?? closeModal}
      onBack={onBackCallback}
      title={t('Filter')}>
      <FlexCol gap={16}>
        {showSearchBar && (
          <Input
            type="search"
            size="full"
            placeholder={searchBarPlaceholderText ?? t('translation:SearchFilterOptionPlaceholder')}
            onChange={(e) => setSearchQuery(e.target.value)}
            checked={true}
            value={searchQuery}
          />
        )}
        {filterSectionList.map(({data, sectionTitle, type, sectionId, dontCollaplseOptionsAboveThreshold}) => {
          const filterType = generateFilterSectionType(type);
          // SECTION WITH ITS TITLE
          return (
            <FlexCol
              alignItems="flex-start"
              key={sectionTitle}
              gap={8}>
              <H3 as="h2">{sectionTitle}</H3>
              {data.map(({id, label, iconName, avatarData}) => (
                // ACTUAL INPUTS OF THE SECTION
                <StyledInputLabel
                  id={sectionTitle}
                  key={id}>
                  <FlexRow
                    gap={16}
                    alignItems="center">
                    {avatarData && (
                      <Avatar
                        user={{
                          email: avatarData.email,
                          initials: avatarData.initials,
                          imageHash: avatarData.imageHash,
                        }}
                      />
                    )}
                    {iconName ? (
                      <StyledIcon
                        icon={iconName as IconNames}
                        size={pxToRem(20)}
                      />
                    ) : null}
                    <Span>{label}</Span>
                  </FlexRow>
                  <Input
                    type={filterType}
                    name={sectionTitle}
                    value={id}
                    checked={localFilters[sectionId]?.includes(id)}
                    onChange={() => handleOnChange({sectionId, type, id})}
                    onKeyDown={(e) => (e.key === 'Enter' ? handleOnChange({sectionId, type, id}) : null)}
                  />
                </StyledInputLabel>
              ))}
              {getShouldShowMoreLink(filterSections, sectionId) ? (
                <Div
                  tabIndex={0}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleShowMore(sectionId) : null)}
                  onClick={() => {
                    handleShowMore(sectionId);
                  }}
                  style={{cursor: 'pointer'}}>
                  <LearnMore>{showMorePerSection[sectionId] ? t('ShowLess') : t('ShowMore')}</LearnMore>
                </Div>
              ) : null}
            </FlexCol>
          );
        })}
      </FlexCol>
    </ModalPage>
  );
};

const getShouldShowMoreLink = (filterSections: FilterConfigType<FilterOption>[], sectionId: string) => {
  const originalSection = filterSections.find((d) => d.sectionId === sectionId);
  if (
    !originalSection ||
    originalSection.dontCollaplseOptionsAboveThreshold ||
    originalSection.data.length <= MAX_ITEMS_IN_LIST
  )
    return false;

  return true;
};
