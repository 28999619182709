import {useTranslation} from 'react-i18next';
import {ProfileCell} from '../../styles';
import {P} from '@quarks';
import {useAppSelector, useModal} from '@hooks';
import {getIsParkingFeatureVisible} from '@lib/store';
import {StyledIcon, StyledClickableCellWrapper} from './styles';
import {pxToRem} from '@utils';
import {TwoColumnCell} from '@atoms';
import {LicensePlatesModalPage} from '../LicensePlatesModalPage/LicensePlatesModalPage';

export const LicensePlateCell = () => {
  const {addPages, openModal} = useModal();
  const {t} = useTranslation();
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);

  const buttonAriaLabel = t('profile:ManageLicensePlates');

  if (!isParkingFeatureVisible) {
    return <></>;
  }

  const openLicensePlates = () => {
    addPages([<LicensePlatesModalPage />]);
    openModal();
  };

  return (
    <ProfileCell>
      <StyledClickableCellWrapper onClick={openLicensePlates}>
        <TwoColumnCell
          padding="small"
          alignItems="center"
          left={<P>{t('profile:LicensePlate.LicensePlate_plural')}</P>}
          right={
            <StyledIcon
              icon="caretRight"
              size={pxToRem(24)}
              aria-label={buttonAriaLabel}
            />
          }
        />
      </StyledClickableCellWrapper>
    </ProfileCell>
  );
};
