import React from 'react';
import {Icon} from '@atoms';
import {Button} from '@molecules';
import {useTranslation} from 'react-i18next';

export const BackButton = ({onBack}: {onBack: () => void}) => {
  const {t} = useTranslation();

  return (
    <div className="flex items-center gap-3">
      <Button
        button="text"
        onClick={onBack}
        size="full"
        className="mb-8 w-auto">
        <Icon
          icon="arrowLeft"
          className="size-4 text-collaborative-blue-500*"
          color={'currentColor'}
        />

        {t('Back')}
      </Button>
    </div>
  );
};
