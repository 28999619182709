import {useBreakPoint} from '@hooks';
import {NextWorkdaysSimplified} from './NextWorkdaysSimplified';

type Props = {
  referenceDate: Date;
};

export const NextWorkdaysSimplifiedSideOverview = ({referenceDate}: Props) => {
  const breakpoint = useBreakPoint();

  if (breakpoint === 'small') return <></>;

  return <NextWorkdaysSimplified referenceDate={referenceDate} />;
};
