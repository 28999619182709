import {useAppDispatch, useAppSelector, useDialog, useModal} from '@hooks';
import {useTranslation} from 'react-i18next';
import {StyledIconButton, StyledClickableCellWrapper} from './styles';
import {P} from '@quarks';
import {LicensePlatesFormModalPage} from '../LicensePlateFormModalPage/LicensePlatesFormModalPage';
import {getLicensePlates, updateLicensePlates, withAsyncThunkErrorHandling} from '@lib/store';
import {HTMLAttributes, MouseEvent, useState} from 'react';
import {TwoColumnCell} from '@atoms';

export type Props = HTMLAttributes<HTMLDivElement> & {
  buildingId?: string;
  licensePlate?: string;
};

export const LicensePlateItem = ({buildingId, licensePlate = ''}: Props) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {addPages} = useModal();
  const {destructiveDialog} = useDialog();
  const [loading, setLoading] = useState(false);
  const licensePlates = useAppSelector(getLicensePlates);

  const editLicensePlate = () => {
    addPages([
      <LicensePlatesFormModalPage
        passedLicensePlate={licensePlate}
        buildingId={buildingId}
      />,
    ]);
  };

  const deleteLicensePlate = async () => {
    const next = licensePlates.filter((lp) => lp !== licensePlate);
    setLoading(true);
    await dispatch(withAsyncThunkErrorHandling(() => updateLicensePlates(next)));
    setLoading(false);
  };

  const showDeleteLicensePlateDialog = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const confirmation = await destructiveDialog(
      t('profile:DeleteLicensePlateTitle'),
      t('profile:DeleteLicensePlateDescription'),
      'car',
    );

    if (confirmation) {
      await deleteLicensePlate();
    }
  };

  return (
    <StyledClickableCellWrapper onClick={editLicensePlate}>
      <TwoColumnCell
        padding="small"
        left={<P minWidth="40px">{licensePlate}</P>}
        right={
          <StyledIconButton
            disabled={loading}
            icon="trash"
            size="small"
            iconButton="tertiary"
            aria-label="Delete License plate"
            onClick={showDeleteLicensePlateDialog}
            data-testid="organisms-profile-licensePlates-LicensePlateItem__delete-button"
          />
        }
      />
    </StyledClickableCellWrapper>
  );
};
