import {UseTranslationResponse, useTranslation} from 'react-i18next';
import {Namespace} from 'i18next';

import {
  useAppDispatch,
  useAppSelector,
  getWorkdayByDate,
  getIsWorkdayLoading,
  Workday,
  withAsyncThunkErrorHandling,
  getAvailableDeskAmenitiesForBuilding,
  getAvailableActivityTypesForBuilding,
  getSelectedBuildingNodeId,
  getAvailableDeskAmenityDataByBuildingId,
  getAvailableActivityTypeDataByBuildingId,
} from '@lib/store';
import {useModal} from '@hooks';
import {resolveWorkdayStatusIcon} from '@utils';

import {BookingLocationStatusCard} from '@organisms';
import {ComponentProps, useEffect} from 'react';
import {Icon} from '@atoms';
import {TileItem} from '../../TileItem';

export const WorkdayTile = ({date, ...props}: ComponentProps<typeof TileItem> & {date: string}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {addPages, openModal} = useModal();

  const workday = useAppSelector((state) => getWorkdayByDate(state, date));

  const loading = useAppSelector(getIsWorkdayLoading);

  const buildingId = useAppSelector(getSelectedBuildingNodeId);
  const availableDeskAmenityData = useAppSelector(getAvailableDeskAmenityDataByBuildingId(buildingId));
  const availableActivityTypeData = useAppSelector(getAvailableActivityTypeDataByBuildingId(buildingId));

  useEffect(() => {
    if (!buildingId) return;

    if (availableDeskAmenityData.status === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableDeskAmenitiesForBuilding({buildingId})));
    }

    if (availableActivityTypeData.status === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableActivityTypesForBuilding({buildingId})));
    }
  }, [dispatch, buildingId, availableDeskAmenityData.status, availableActivityTypeData.status]);

  return (
    <TileItem
      data-testid="workday-tile"
      className="bg-energizing-yellow-500*" // is always highlighted
      onClick={() => {
        addPages([<BookingLocationStatusCard date={date} />]);
        openModal();
      }}
      {...props}>
      <Icon
        className="size-5"
        icon={resolveWorkdayStatusIcon(loading, workday)}
      />
      <h4 className="text-left">{resolveLabel(t, loading, workday)}</h4>

      <Icon
        className="size-5 justify-self-end"
        icon={'caretRight'}
      />
    </TileItem>
  );
};

const resolveLabel = (
  t: UseTranslationResponse<Namespace, undefined>['t'],
  loading: boolean,
  workday: Workday | undefined,
) => {
  if (loading) return t('workplace:UpdatingWorkday');
  if (!workday) return t('workplace:WhereWillYouBe');

  switch (workday.status) {
    case 'NotWorking':
      return t('workplace:NotWorking');
    case 'WorkingRemotely':
      return t('workplace:WorkingRemotely');
    case 'OfficeDay':
      return workday.nodeName;
  }
};
