import {Icon, Loader} from '@atoms';
import {Button, Input} from '@molecules';
import {FlexCol, H1, P} from '@quarks';
import {StyledCard, CardTop, CardBody, CardBottom} from '../styles';
import {useTranslation} from 'react-i18next';
import {usePreferencesOverview} from '@lib/logic';
import {OnboardingStep} from '../types';
import {DeskAmenitiesList} from '@organisms';
import {getIsLoadingParkingLots, useAppSelector} from '@lib/store';
import {BackButton} from '../components';

interface Props {
  buildingId: string;
  floorId?: string | null;
  areaId?: string | null;
  deskAmenities: string[];
  parkingLotId?: string | null;
  parkingZoneId?: string | null;
  isDeskRequired?: boolean;

  isSaving?: boolean;

  onSetIsDeskRequired: (value: boolean) => void;
  onBack?: () => void;
  onSetStep: (step: OnboardingStep) => void;
  onSave: () => void;
}

export const OnboardingOverviewCard = ({
  buildingId,
  floorId = null,
  areaId = null,
  deskAmenities,
  parkingLotId = null,
  parkingZoneId = null,
  isDeskRequired = false,

  isSaving,

  onSetIsDeskRequired,
  onBack,
  onSetStep,
  onSave,
}: Props) => {
  const {t} = useTranslation();
  const isLoadingParkingLots = useAppSelector(getIsLoadingParkingLots);

  const {
    isParkingFeatureVisible,

    selectedFloor,
    selectedParkingLot,

    workspaceLabel,
    parkingLabel,
  } = usePreferencesOverview({
    buildingId,
    floorId,
    areaId,
    parkingLotId,
    parkingZoneId,
    isDeskRequired,
    deskAmenities,
  });

  return (
    <StyledCard
      maxHeight="80vh"
      height="100%">
      <CardTop>
        <FlexCol gap={16}>
          {onBack && <BackButton onBack={onBack} />}
          <H1 id="onboarding-legend">{t('workplace:AllSet')}</H1>
          <P>{t('workplace:AllSetDescription')}</P>
        </FlexCol>
      </CardTop>

      <CardBody
        aria-labelledby="onboarding-legend"
        role="radiogroup"
        tabIndex={0}
        className="mt-3 pb-8 min-h-40">
        <div className="flex flex-col gap-8">
          {/* Floor & area */}
          <div className="flex items-end justify-between">
            <div>
              <h3 className="mb-2">{t('workplace:Workspace')}</h3>

              {selectedFloor ? (
                <p>{workspaceLabel}</p>
              ) : (
                <p className="text-mapiq-black-500">{t('workplace:NoPreferredWorkspace')}</p>
              )}
            </div>

            <Button
              button="secondary light"
              onClick={() => onSetStep(OnboardingStep.Floor)}
              size="small"
              className="w-10 h-10 p-0 mb-5 rounded-md bg-mapiq-black-800*/[.03]">
              <Icon
                icon={selectedFloor ? 'pencil' : 'plus'}
                size="1.1rem"
              />
            </Button>
          </div>

          {/* Desk amenities */}
          <div className="flex items-start justify-between">
            <div>
              <h3 className="mb-2">{t('deskAmenities:deskAmenity_plural')}</h3>

              {deskAmenities.length ? (
                <DeskAmenitiesList deskAmenities={deskAmenities} />
              ) : (
                <p className="text-mapiq-black-500">{t('workplace:NoDeskAmenitiesSelected')}</p>
              )}
            </div>

            <Button
              button="secondary light"
              onClick={() => onSetStep(OnboardingStep.DeskAmenities)}
              size="small"
              className="w-10 h-10 p-0 mb-5 rounded-md bg-mapiq-black-800*/[.03]">
              <Icon
                icon={deskAmenities.length ? 'pencil' : 'plus'}
                size="1.1rem"
              />
            </Button>
          </div>

          {/* Automatic desk booking */}
          <div>
            <h3 className="mb-2">{t('workplace:WorkspaceBooking')}</h3>

            <div className="flex flex-col gap-2">
              <label className="flex flex-row gap-3 cursor-pointer">
                <Input
                  type="radio"
                  name="isDeskRequired"
                  value="deskRequired"
                  checked={isDeskRequired}
                  onChange={() => onSetIsDeskRequired(true)}
                  className="self-baseline"
                />
                <div>
                  <p className="font-semibold">{t('workplace:Automatic')}</p>
                  <p>{t('workplace:AutomaticDeskBookingLabel')}</p>
                </div>
              </label>

              <label className="flex flex-row gap-3 cursor-pointer">
                <Input
                  type="radio"
                  name="isDeskRequired"
                  value="deskOptional"
                  checked={!isDeskRequired}
                  onChange={() => onSetIsDeskRequired(false)}
                  className="self-baseline"
                />
                <div>
                  <p className="font-semibold">{t('workplace:Manual')}</p>
                  <p>{t('workplace:ManualDescription')}</p>
                </div>
              </label>
            </div>
          </div>

          {/* Parking */}
          {isParkingFeatureVisible && (
            <div className="flex items-end justify-between">
              <div>
                <h3 className="mb-2">{t('parking:ParkingSpot')}</h3>

                {isLoadingParkingLots ? (
                  <Loader size="1.2rem" />
                ) : selectedParkingLot ? (
                  <p>{parkingLabel}</p>
                ) : (
                  <p className="text-mapiq-black-500">{t('parking:NoPreferredParking')}</p>
                )}
              </div>

              <Button
                button="secondary light"
                onClick={() => onSetStep(OnboardingStep.ParkingLot)}
                size="small"
                className="w-10 h-10 p-0 mb-5 rounded-md bg-mapiq-black-800*/[.03]">
                <Icon
                  icon={selectedParkingLot ? 'pencil' : 'plus'}
                  size="1.1rem"
                />
              </Button>
            </div>
          )}
        </div>
      </CardBody>

      <CardBottom>
        <Button
          button="primary"
          onClick={onSave}
          loading={isSaving}
          size="full">
          {t('workplace:LetsGo')}
        </Button>
      </CardBottom>
    </StyledCard>
  );
};
