import {UseTranslationResponse, useTranslation} from 'react-i18next';
import {Namespace} from 'i18next';
import {CalendarWorkdayTileProps} from './types';

import {
  getWorkdayByDate,
  ExtrasTypes,
  getIsWorkdayLoading,
  Workday,
  withAsyncThunkErrorHandling,
  getAvailableDeskAmenitiesForBuilding,
  getAvailableActivityTypesForBuilding,
  getSelectedBuildingNodeId,
  getAvailableDeskAmenityDataByBuildingId,
  getAvailableActivityTypeDataByBuildingId,
  getIsParkingFeatureVisible,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {resolveWorkdayStatusIcon} from '@utils';

import {ButtonGroup, SquareButton} from '@molecules';
import {
  CalendarWorkspaceTile,
  BookingLocationStatusCard,
  CalendarWorkdayInvitationList,
  CalendarParkingTile,
  ExtrasTile,
} from '@organisms';
import {useEffect} from 'react';

export const CalendarWorkdayTile = ({date}: CalendarWorkdayTileProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {addPages, openModal} = useModal();

  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const loading = useAppSelector(getIsWorkdayLoading);

  const buildingId = useAppSelector(getSelectedBuildingNodeId);
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);

  const availableDeskAmenityData = useAppSelector(getAvailableDeskAmenityDataByBuildingId(buildingId));
  const availableActivityTypeData = useAppSelector(getAvailableActivityTypeDataByBuildingId(buildingId));

  useEffect(() => {
    if (!buildingId) return;

    if (availableDeskAmenityData.status === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableDeskAmenitiesForBuilding({buildingId})));
    }

    if (availableActivityTypeData.status === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableActivityTypesForBuilding({buildingId})));
    }
  }, [dispatch, buildingId, availableDeskAmenityData.status, availableActivityTypeData.status]);

  return (
    <ButtonGroup>
      <SquareButton
        disabled={loading}
        loading={loading}
        data-testid="workday-tile"
        onClick={() => {
          addPages([<BookingLocationStatusCard date={date} />]);
          openModal();
        }}
        iconLeft={resolveWorkdayStatusIcon(loading, workday)}
        iconRight="caretRight"
        squareButton="energizingYellow">
        {resolveLabel(t, loading, workday)}
      </SquareButton>

      {workday?.status === 'OfficeDay' && <CalendarWorkspaceTile date={date} />}

      <CalendarWorkdayInvitationList
        date={date}
        color="energizingYellow"
      />

      {workday?.status === 'OfficeDay' && isParkingFeatureVisible && <CalendarParkingTile date={date} />}
      {workday?.status === 'OfficeDay' && <ExtrasTile extrasType={ExtrasTypes.WORKDAY} />}
    </ButtonGroup>
  );
};

const resolveLabel = (
  t: UseTranslationResponse<Namespace, undefined>['t'],
  loading: boolean,
  workday: Workday | undefined,
) => {
  if (loading) return t('workplace:UpdatingWorkday');
  if (!workday) return t('workplace:WhereWillYouBe');

  switch (workday.status) {
    case 'NotWorking':
      return t('workplace:NotWorking');
    case 'WorkingRemotely':
      return t('workplace:WorkingRemotely');
    case 'OfficeDay':
      return workday.nodeName;
  }
};
