import {useTranslation} from 'react-i18next';
import {NotificationsCardCellProps} from './types';
import {StyledBadge, StyledH3, StyledIconWrapper, StyledNotificationCardCell, StyledWrapper} from './styles';

import {formatDate, isBeforeToday} from '@lib/utils';
import {pxToRem} from '@utils';

import {useSidePanel} from '@hooks';
import {
  deleteNotification,
  withAsyncThunkErrorHandling,
  getAppLanguage,
  useAppDispatch,
  useAppSelector,
  getCustomActionCategories,
  handleNotificationCustomAction,
} from '@lib/store';
import {getBadgeProps, getNotificationIcon} from './helpers';

import {Div, FlexRow, Subtitle} from '@quarks';
import {Icon} from '@atoms';
import {Badge, Button, IconButton} from '@molecules';
import {HighlightedText} from '@organisms';
import {isAfter, parseISO, startOfToday} from 'date-fns';
import {trackEvent} from '@lib/infrastructure';
import {useTransformNotification} from '@lib/logic';

export const NotificationsCardCell = ({notification}: NotificationsCardCellProps) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const appLanguage = useAppSelector(getAppLanguage);

  const {notificationType, title, message, transformedMessageValues, timeAgoText, isRead, icon} =
    useTransformNotification(notification, getNotificationIcon, t, appLanguage);

  const {closeSidePanel} = useSidePanel();

  const formatedDate = transformedMessageValues.ReferenceDate
    ? formatDate(transformedMessageValues.ReferenceDate, 'yyyy-MM-dd', 'en')
    : null;
  const isDayInPast =
    !!transformedMessageValues.ReferenceDate && isBeforeToday(transformedMessageValues.ReferenceDate, startOfToday());

  const handleDeleteNotification = () => {
    dispatch(withAsyncThunkErrorHandling(() => deleteNotification(notification))).then(() => {
      trackEvent('Notifications_Notification__Delete', {
        type: notificationType,
        date: formatedDate,
      });
    });
  };

  const actions = getCustomActionCategories(t);
  const relevantActions = notification.customAction ? actions[notification.customAction]?.actions ?? [] : [];
  const disableActions =
    notification.isCustomActionPerformed ||
    (!!notification.customActionDeadline && isAfter(new Date(), parseISO(notification.customActionDeadline)));

  const dayOverviewLink = `/${formatedDate}`;

  return (
    <StyledNotificationCardCell key={notification.id}>
      <StyledIconWrapper
        card="beige500"
        shadow={false}>
        <Icon
          color="currentColor"
          icon={icon}
          size={pxToRem(24)}
        />
        <Badge
          border="2px solid white"
          bottom={pxToRem(4)}
          right={pxToRem(4)}
          size="large"
          {...getBadgeProps(notificationType)}
        />
      </StyledIconWrapper>

      <StyledWrapper $isPast={isDayInPast}>
        <FlexRow alignItems="center">
          <StyledH3
            as="h2"
            color="inherit">
            {title}
          </StyledH3>
          {!isRead && (
            <StyledBadge
              aria-label={t('notifications:UnreadNotification')}
              badge="notification"
              role="img"
            />
          )}
        </FlexRow>
        <span>
          <HighlightedText
            onClick={() => {
              trackEvent('Notifications_Notification__Click', {
                type: notificationType,
                date: formatedDate,
              });
              closeSidePanel({silent: true});
            }}
            text={message}
            highlight={transformedMessageValues.ShiftDate || transformedMessageValues.DateRange}
            highlightTo={!isDayInPast ? dayOverviewLink : undefined}
          />
        </span>

        <Subtitle>{timeAgoText}</Subtitle>
        {!!relevantActions.length && (
          <FlexRow
            justifyContent="flex-start"
            columnGap={8}>
            {relevantActions.map((a) => (
              <Button
                disabled={disableActions}
                button={a.destructive ? 'secondary destructive' : 'tertiary'}
                key={a.id}
                onClick={() =>
                  dispatch(
                    withAsyncThunkErrorHandling(() =>
                      handleNotificationCustomAction({
                        notificationId: notification.id,
                        type: a.id,
                        notificationMessageValues: notification.messageValues,
                      }),
                    ),
                  )
                }>
                {a.title}
              </Button>
            ))}
          </FlexRow>
        )}
      </StyledWrapper>

      <Div alignSelf="center">
        <IconButton
          aria-label={t('notifications:DeleteNotification')}
          iconButton="tertiary"
          icon="trash"
          size="small"
          onClick={handleDeleteNotification}
        />
      </Div>
    </StyledNotificationCardCell>
  );
};
