import {CalendarWorkdayInvitationListProps} from './types';

import {useAppSelector} from '@hooks';
import {getPendingWorkspaceReservationsByDate} from '@lib/store';
import {parseISO} from 'date-fns';
import {CalendarWorkdayInvitationCell} from '@organisms';
import {useMemo} from 'react';

export const CalendarWorkdayInvitationList = ({
  date,
  color = 'energizingYellow',
}: CalendarWorkdayInvitationListProps) => {
  const dateObject = useMemo(() => parseISO(date), [date]);
  const pendingWorkspaceReservations = useAppSelector((state) =>
    getPendingWorkspaceReservationsByDate(state, dateObject),
  );

  return (
    <>
      {!!pendingWorkspaceReservations?.length &&
        pendingWorkspaceReservations.map((workspace) => (
          <CalendarWorkdayInvitationCell
            {...{
              workspace,
              color,
              key: workspace.id,
              date,
            }}
          />
        ))}
    </>
  );
};
