import {useAppSelector, useModal} from '@hooks';
import {getListOfSelectableOfficeNodesByType} from '@lib/store';
import {BookingLocationCellItem, ModalPage} from '@organisms';
import {Div, FlexCol, P, Subtitle} from '@quarks';
import {parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {BookingLocationStatusCardProps} from './types';
import {useMemo} from 'react';

export const BookingLocationStatusCard = ({date}: BookingLocationStatusCardProps) => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const dateInstance = useMemo(() => parseISO(date), [date]);
  const buildings = useAppSelector((state) => getListOfSelectableOfficeNodesByType(state, dateInstance));

  return (
    <ModalPage
      onClose={closeModal}
      title={t('workplace:YourLocation')}>
      <FlexCol gap={32}>
        {buildings.map((buildingSection) => (
          <Div key={buildingSection.key}>
            {buildingSection.key === 'Unavailable' && (
              <P
                fontWeight="bold"
                color="currentColor">
                {t('workplace:WorkspaceGroupUnavailable')}
              </P>
            )}
            {buildingSection.data.map((building) => (
              <BookingLocationCellItem
                key={building.id ?? building.name}
                date={date}
                building={building}
              />
            ))}
          </Div>
        ))}
        <Subtitle fontSize="sm">{t('workplace:NoOfficeInTheList')}</Subtitle>
      </FlexCol>
    </ModalPage>
  );
};
