import {Navigation} from '@organisms';

import {useFeatureToggle, useShowNavigation, useTheme} from '@hooks';
import {HeaderLayout} from '@templates';
import {useTranslation} from 'react-i18next';
import {StyledNavLink} from './styles';
import {MapiqLogo} from '@atoms';
import {Navigation_ExperienceImprovements} from '../../Navigation_ExperienceImprovements';

export const Header = () => {
  const showNavigation = useShowNavigation();
  const {t} = useTranslation();
  const {theme} = useTheme();
  const enabledFeatures = useFeatureToggle();

  return enabledFeatures.ExperienceImprovements ? (
    <div
      className="hidden sm:flex items-center justify-center border-b sticky px-4 top-0 z-10 bg-white"
      style={{gridArea: 'header / fullbleed'}}>
      {showNavigation && <Navigation_ExperienceImprovements footer={false} />}
    </div>
  ) : (
    <HeaderLayout>
      {showNavigation && (
        <>
          <StyledNavLink
            to="/"
            aria-label={t('GoHome')}>
            <MapiqLogo
              data-testid="organisms-header-logo"
              theme={theme === 'light' ? 'dark' : 'light'}
              type="full"
              width="105px"
            />
          </StyledNavLink>

          <Navigation footer={false} />
        </>
      )}
    </HeaderLayout>
  );
};
