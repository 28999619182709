import {useTranslation} from 'react-i18next';

import {
  useAppSelector,
  getIsWorkdayLoading,
  getWorkspaceReservationByDate,
  getIsWorkspaceReservationButtonEnabled,
  getIsLoadingWorkspaceReservations,
} from '@lib/store';
import {useModal} from '@hooks';

import {BookingDetailsCard, BookWorkspaceCard, CalendarWorkspaceTileBookingInfo} from '@organisms';
import {ComponentProps, useMemo} from 'react';
import {Icon, Loader} from '@atoms';
import {parseISO} from 'date-fns';
import {TileItem} from '../../TileItem';
import {trackEvent} from '@lib/infrastructure';
import {TileMapPreview} from '../../TileMapPreview';

export const WorkspaceTile = ({date, ...props}: ComponentProps<typeof TileItem> & {date: string}) => {
  const {t} = useTranslation();
  const {addPages, openModal} = useModal();

  const workDayPending = useAppSelector(getIsWorkdayLoading);
  const workspacePending = useAppSelector(getIsLoadingWorkspaceReservations);

  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));

  const dateAsDate = useMemo(() => parseISO(date), [date]);
  const isWorkspaceEnabled =
    useAppSelector((state) => getIsWorkspaceReservationButtonEnabled(state, dateAsDate)) && !workDayPending;
  const isGroupBooking = !!workspaceReservation?.groupReservationId;

  const iconName = useMemo(() => {
    if (!workspaceReservation || workspaceReservation.deskId) {
      return 'desk';
    }

    if (workspaceReservation.areaId) {
      return 'area';
    }

    if (workspaceReservation.floorId) {
      return 'floor';
    }

    return 'office';
  }, [workspaceReservation]);

  return (
    <>
      <TileMapPreview workspaceReservation={workspaceReservation} />
      <TileItem
        data-testid="workspace-tile"
        onClick={() => {
          if (workspaceReservation) {
            addPages([
              <BookingDetailsCard
                date={date}
                workspaceReservation={workspaceReservation}
              />,
            ]);
            openModal();

            trackEvent('CalendarOverview_BookingDetails__Open', {
              date,
              isGroupBooking,
            });
          } else {
            if (!isWorkspaceEnabled) return;

            addPages([<BookWorkspaceCard date={date} />]);
            openModal();
          }
        }}
        {...props}>
        <Icon
          className="size-5"
          icon={iconName}
        />
        <div>{!workspacePending && <CalendarWorkspaceTileBookingInfo date={date} />}</div>

        {workspacePending ? (
          <Loader className="size-6 justify-self-end" />
        ) : props.active ? (
          <span className="size-8 bg-connecting-green-500* rounded-md flex justify-center items-center">
            <Icon
              className="size-5 [&>path]:stroke-2"
              color="white"
              icon={'check'}
            />
          </span>
        ) : (
          <Icon
            className="size-5 justify-self-end"
            icon={'plus'}
          />
        )}
      </TileItem>
    </>
  );
};
