import {AppLinkDto} from '@lib/infrastructure';
import {getNavigationActionFromAppLink, NavigationAction, QueryParamConstants} from '@lib/logic';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

export const useHandleAppLink = () => {
  const navigate = useNavigate();

  // returns true if handled
  return useCallback(
    (link: AppLinkDto): boolean => {
      const {buildingId, locationNodeDisplayName, locationNodeId, intent} = link;

      const navigationAction = getNavigationActionFromAppLink(link);

      switch (navigationAction) {
        case NavigationAction.Explore_Facility:
        case NavigationAction.Explore_Room:
        case NavigationAction.Explore_Area:
          const params = new URLSearchParams({
            [QueryParamConstants.hereAndNow.explore.buildingId]: buildingId,
            [QueryParamConstants.hereAndNow.explore.search]: locationNodeDisplayName || '',
            [QueryParamConstants.hereAndNow.explore.resourceId]: locationNodeId,
            [QueryParamConstants.intent]: intent,
          });
          navigate(`/now/explore?${params.toString()}`);
          return true;

        case NavigationAction.Unknown:
          return false;
      }
    },
    [navigate],
  );
};
