import {Icon} from '@atoms';
import {useModal} from '@hooks';
import {trackEvent} from '@lib/infrastructure';
import {useAppSelector, getIsMapLoaded, WorkspaceReservation} from '@lib/store';
import {FacilityMarkerData} from '@map/mapiq-map/markers/NodeMarkerData';
import {MapView, ExpandMapButton} from '@molecules';
import {cn} from '@utils';
import {RenderModeToggle} from 'src/components/molecules/MapView/RenderModeToggle/RenderModeToggle';
import {useGlobalMapTypePreference} from 'src/components/molecules/MapView/useGlobalMapTypePreference';
import {BigMapModal} from 'src/components/organisms/BigMapModal';
import {useFacilityMarkers} from 'src/components/organisms/hereAndNow/useFacilityMarkers';

const abortIfAlreadyInView = false;
const viewportRestriction = {
  paddingBottomPixels: 100,
  paddingLeftPixels: 60,
  paddingRightPixels: 60,
  paddingTopPixels: 100,
} as const;
export const TileMapPreview = ({workspaceReservation}: {workspaceReservation?: WorkspaceReservation | null}) => {
  const {openModal, setModalPages, setBigPages} = useModal();

  const location = {
    buildingId: workspaceReservation?.currentMember?.location.buildingId ?? null,
    floorId: workspaceReservation?.currentMember?.location.floorId ?? null,
    nodeId: workspaceReservation?.currentMember?.nodeId ?? null,
  };
  const facilityMarkers = useFacilityMarkers(
    workspaceReservation?.currentMember?.location.buildingId ?? null,
    workspaceReservation?.currentMember?.location.floorId ?? null,
  );

  const [type] = useGlobalMapTypePreference('MapPreview');
  const mapIsLoaded = useAppSelector(getIsMapLoaded(location.buildingId, location.floorId, type));

  const expandMap = () => {
    if (!workspaceReservation) return;
    const workspaceLabel = workspaceReservation
      ? [workspaceReservation.floorName, workspaceReservation.areaName, workspaceReservation.deskName]
          .filter((x) => x)
          .join(', ')
      : 'No workspace reservation';

    const mapView = (
      <BigMapModal
        title="Workspace"
        main={
          <MapView
            buildingId={location.buildingId!}
            floorId={location.floorId!}
            highlights={[...facilityMarkers, mapMarker]}
            buildingNodeStates={new Map([[mapMarker.nodeId, 'selected']])}
            buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
            fullView={true}
            borderRadius={0}
            disablePointerEvents={false}
            elementsToKeepInView={[mapMarker.nodeId]}
            abortIfAlreadyInView={abortIfAlreadyInView}
            viewportRestrictions={viewportRestriction}
            mapTypeNamespace="MapPreview"
          />
        }
        footer={
          <div className="flex gap-4 justify-start p-4">
            <div className="flex flex-col justify-center items-center bg-beige-500* h-12 w-12 rounded-lg flex-shrink-0">
              <Icon
                icon="desk"
                size="24px"
              />
            </div>
            <div className="flex flex-col">
              <h4>{workspaceReservation.currentMember?.location.areaName}</h4>
              <p>{workspaceLabel}</p>
            </div>
          </div>
        }
      />
    );

    trackEvent('Home_WorkdayTile__ExpandMap');
    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  };

  if (!location.buildingId || !location.floorId) return null;

  const mapMarker: FacilityMarkerData = {
    type: 'facility',
    subType: 'Area',
    nodeId: location.nodeId!,
  };

  return (
    <div
      className={cn('aspect-video col-span-full max-h-60 w-full h-0 relative overflow-hidden', {
        'h-full border-t border-white': mapIsLoaded,
      })}>
      <MapView
        buildingId={location.buildingId!}
        floorId={location.floorId!}
        highlights={[...facilityMarkers, mapMarker]}
        buildingNodeStates={new Map([[mapMarker.nodeId, 'selected']])}
        buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
        fullView={true}
        borderRadius={0}
        disablePointerEvents={true}
        abortIfAlreadyInView={abortIfAlreadyInView}
        elementsToKeepInView={[mapMarker.nodeId]}
        viewportRestrictions={viewportRestriction}
        mapTypeNamespace="MapPreview"
      />
      <ExpandMapButton
        onClick={expandMap}
        className={'absolute top-4 right-4'}
      />
      <RenderModeToggle
        mapTypeNamespace="MapPreview"
        position="bottom-left"
        padding={8}
      />
    </div>
  );
};
