import {getSettings, getUserFirstName} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {useAppSelector, useFeatureToggle} from '@hooks';

import {Button} from '@molecules';
import {MoreInformationCell, OnboardingWrapper, QuestionsCell, QuotaCard} from '@organisms';
import {FlexCol, H1, H2, P} from '@quarks';
import {CardBottom, CardTop, QuotaWrapper, WelcomeCard} from '../styles';
import {Icon} from '@atoms';
import {BackButton} from '../components';

export type OnboardingWelcomeCardProps = {
  onNext: () => void;
  onBack?: () => void;
};

export const OnboardingWelcomeCard = ({onNext, onBack}: OnboardingWelcomeCardProps) => {
  const userFirstName = useAppSelector(getUserFirstName);
  const {officeInstructions} = useAppSelector(getSettings);
  const {t} = useTranslation();
  const {ExperienceImprovements: isExperienceImprovementsEnabled} = useFeatureToggle();

  return (
    <OnboardingWrapper>
      <CardTop>
        <FlexCol gap={16}>
          {onBack && <BackButton onBack={onBack} />}

          <H1>{t('profile:HelloMessage', {name: userFirstName})}</H1>
        </FlexCol>
      </CardTop>
      <WelcomeCard>
        <P>{officeInstructions}</P>
        <QuotaWrapper>
          <H2>{t('profile:YourQuota')}</H2>
          <QuotaCard />
        </QuotaWrapper>
        <FlexCol>
          <QuestionsCell />
          <hr />
          <MoreInformationCell />
        </FlexCol>
      </WelcomeCard>
      <CardBottom>
        <Button
          button="primary"
          onClick={onNext}
          size="full">
          {isExperienceImprovementsEnabled ? t('Next') : t('Continue')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
