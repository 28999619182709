import {getIsExperienceImprovementsEnabled, useAppSelector, type Extra} from '@lib/store';
import {FlexCol, H3} from '@quarks';
import {ExtraTile, Tile} from '@organisms';
import {cn, getExtraItem} from '@utils';
import {useTranslation} from 'react-i18next';
import {useBreakPoint} from '@hooks';
import {PropsWithChildren} from 'react';
import {ExtrasLoader} from './ExtrasLoader';

export const ExtrasOverview = ({extras, loading}: {extras: Extra[]; loading: boolean}) => {
  const bp = useBreakPoint();
  const {t} = useTranslation();
  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);
  const extraItems = extras.map((e) => getExtraItem(e));

  // Hide when loaded & empty
  if (!loading && extras.length === 0) return <></>;

  // In desktop view, the extras appear in a regular flex layout in the sidebar.
  // On mobile, they need to be wrapped in a card to look similar to the other sections
  const Wrapper = isExperienceImprovementsEnabled ? TileWrapper : bp === 'small' ? TileWrapper : SideWrapper;

  return (
    <Wrapper title={t('Services')}>
      {loading ? (
        <ExtrasLoader />
      ) : (
        <div className={cn('gap-6 w-full grid grid-cols-1', {'grid-cols-2': bp === 'large'})}>
          {extraItems.map(({iconLeft, name, url, id}) => {
            return (
              <ExtraTile
                key={id}
                iconName={iconLeft}
                title={name}
                url={url}
              />
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

type WrapperProps = PropsWithChildren<{title: string}>;

const TileWrapper = ({title, children}: WrapperProps) => {
  return <Tile title={title}>{children}</Tile>;
};

const SideWrapper = ({title, children}: WrapperProps) => {
  return (
    <FlexCol
      gap={16}
      className="w-full">
      <H3 className="pl-0">{title}</H3>
      {children}
    </FlexCol>
  );
};
