import {ReactComponent as GoogleButton} from '@svgs/google_sign_in.svg';
import {
  getCalendarIntegrationType,
  getHasUserGivenCalendarConsent,
  getIsMeetingsCalendarEnabled,
  getIsUserCalendarConsentInvalid,
  getMeetingsByDate,
  getMeetingsLoadingStatusByDate,
  redirectForCalendarConsent,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle, useModal} from '@hooks';

import {CalendarEventsListProps} from './types';
import {parseISO, startOfToday} from 'date-fns';

import {useTranslation} from 'react-i18next';
import {Illustration} from '@atoms';
import {Button, SquareButton} from '@molecules';
import {CalendarEventCell, CreateEventCard} from '@organisms';
import {isBeforeToday} from '@lib/utils';
import {CardWrapper, CardWrapperEmpty} from './styles';
import {Div} from '@quarks';

export const CalendarEventsList = ({
  date,
  hideCreationButton,
}: CalendarEventsListProps & {hideCreationButton?: boolean}) => {
  const dateAsDate = parseISO(date);
  const {addPages, openModal} = useModal();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const meetings = useAppSelector((state) => getMeetingsByDate(state, dateAsDate));
  const isCalendarConsentGiven = useAppSelector(getHasUserGivenCalendarConsent);
  const isCalendarConsentInvalid = useAppSelector(getIsUserCalendarConsentInvalid);
  const isFailed = useAppSelector((state) => getMeetingsLoadingStatusByDate(state, dateAsDate)) === 'Failed';
  const isCalendarEnabledByAdmin = useAppSelector(getIsMeetingsCalendarEnabled);
  const calendarIntegrationType = useAppSelector(getCalendarIntegrationType);
  const isPast = isBeforeToday(dateAsDate, startOfToday());

  const {HybridMeetingsCalendarView} = useFeatureToggle();

  const shouldShowMeetingCreationButton = isPast // Remove the button for the days in the past
    ? false
    : HybridMeetingsCalendarView && isCalendarEnabledByAdmin && isCalendarConsentGiven && !isCalendarConsentInvalid;

  const handleOpenCreateEvent = () => {
    addPages([<CreateEventCard date={date} />]);
    openModal();
  };

  const title = isCalendarConsentInvalid
    ? t('meeting:LostCalendarConnectionBannerTitle')
    : !isCalendarConsentGiven
    ? t('meeting:CalendarBannerTitle')
    : null;

  const message = isCalendarConsentInvalid
    ? t('meeting:LostCalendarConnectionBannerMessage')
    : !isCalendarConsentGiven
    ? t('meeting:CalendarBannerMessage')
    : isFailed
    ? t('meeting:ErrorOnLoadingMeetings')
    : t('meeting:NoMeetingsForDay');

  const buttonLabel = isCalendarConsentInvalid
    ? t('meeting:LostCalendarConnectionBannerButton')
    : !isCalendarConsentGiven && calendarIntegrationType
    ? t('meeting:CalendarBannerButton', {calendarIntegrationType})
    : null;

  const illustration = isCalendarConsentInvalid
    ? 'error'
    : !isCalendarConsentGiven
    ? 'calendar'
    : isFailed
    ? 'error'
    : 'calendar';

  const ariaLabel = isCalendarConsentInvalid
    ? 'Calendar connection is lost'
    : !isCalendarConsentGiven
    ? 'Calendar consent is not given'
    : isFailed
    ? 'Something went wrong'
    : 'No meetings for today';

  const renderMicrosoftButton = !!buttonLabel && calendarIntegrationType === 'Microsoft 365';
  const renderGoogleButton = !!buttonLabel && calendarIntegrationType === 'Google Calendar';

  const handleButtonClick = () => {
    dispatch(withAsyncThunkErrorHandling(() => redirectForCalendarConsent()));
  };

  return (
    <CardWrapper>
      {!hideCreationButton && shouldShowMeetingCreationButton && (
        <SquareButton
          iconLeft="calendarPlus"
          iconRight="caretRight"
          data-testid="organisms-calendar-CalendarEventsList_create-event-button"
          squareButton="transparent"
          onClick={handleOpenCreateEvent}>
          {t('meeting:CreateMeetingCreateEventButton')}
        </SquareButton>
      )}
      {isCalendarConsentInvalid || !isCalendarConsentGiven || meetings.length === 0 || isFailed ? (
        <CardWrapperEmpty>
          <Illustration
            illustration={illustration}
            size="100px"
            aria-label={ariaLabel}
          />
          <div>
            {title && <b>{title}</b>}
            <p>{message}</p>
          </div>
          {renderMicrosoftButton ? (
            <Button
              data-testid="organisms-calendar-CalendarEventsList_connect-calendar-button-fs"
              button="tertiary"
              onClick={handleButtonClick}
              size="small">
              {buttonLabel}
            </Button>
          ) : renderGoogleButton ? (
            <Div
              style={{cursor: 'pointer', border: 'none'}}
              onClick={handleButtonClick}
              as="button">
              <GoogleButton />
            </Div>
          ) : null}
        </CardWrapperEmpty>
      ) : (
        meetings.map((meeting) => (
          <CalendarEventCell
            meeting={meeting}
            date={date}
            key={meeting.id}
          />
        ))
      )}
    </CardWrapper>
  );
};
