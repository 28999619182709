import {useBreakPoint} from '@hooks';
import {NextWorkdaysSimplified} from './NextWorkdaysSimplified';

type Props = {
  referenceDate: Date;
};

export const NextWorkdaysSimplifiedTile = ({referenceDate}: Props) => {
  const breakpoint = useBreakPoint();

  if (breakpoint !== 'small') return <></>;

  return <NextWorkdaysSimplified referenceDate={referenceDate} />;
};
