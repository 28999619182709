import {useAppSelector} from '@hooks';
import {getConnectedUsersByDate, getWorkspaceReservationByDate} from '@lib/store';
import {UserCell} from '@molecules';
import {Div, P} from '@quarks';
import {parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {BookingDetailsConnectionsProps} from './types';
import {useMemo} from 'react';

export const BookingDetailsConnections = ({date, location}: BookingDetailsConnectionsProps) => {
  const {t} = useTranslation();

  const connectionsParams = useMemo(() => ({date, nodeId: location.nodeId}), [date, location.nodeId]);
  const connections = useAppSelector((state) => getConnectedUsersByDate(state, connectionsParams));

  const dateInstance = useMemo(() => parseISO(date), [date]);
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, dateInstance));

  const getFilteredConnections = () => {
    if (!workspaceReservation?.groupReservationId) {
      return connections;
    }
    const membersWorkspaceReservationIds = workspaceReservation.members.map((member) => member.userId);
    const connectionsWithoutInvitees = connections.filter(
      (connection) => !membersWorkspaceReservationIds.includes(connection.id),
    );
    return connectionsWithoutInvitees;
  };

  const filteredConnections = useMemo(getFilteredConnections, [
    workspaceReservation?.groupReservationId,
    connections,
    workspaceReservation?.members,
  ]);

  return (
    <>
      {filteredConnections.length > 0 ? (
        <Div>
          <P
            fontSize="sm"
            fontWeight="bold">
            {location.isUserLocation ? t('connection:YourLocation') : location.name}
          </P>
          {filteredConnections.map((connection) => (
            <UserCell
              separator={false}
              padding="small"
              key={connection.connectionId}
              user={connection}
            />
          ))}
        </Div>
      ) : null}
    </>
  );
};
