import {cn} from '@utils';
import {PropsWithChildren, ButtonHTMLAttributes} from 'react';

export const TileItem = ({
  className,
  children,
  active,
  isLastActive,
  ...props
}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement> & {active?: boolean; isLastActive?: boolean}>) => {
  return (
    <button
      className={cn(
        'px-4 py-5 grid grid-cols-subgrid gap-4 items-center bg-mapiq-black-800*/[0.03] col-span-full [&>*:first-child]:self-baseline [&>*:last-child]:self-baseline',
        'disabled:opacity-50 disabled:cursor-not-allowed',
        {
          'bg-energizing-yellow-500* first:rounded-t-lg first:border-t-0 border-t border-white': active,
          'rounded-b-lg': isLastActive,
          'mt-4 rounded-lg': !active,
        },
        className,
      )}
      {...props}>
      {children}
    </button>
  );
};
