import {useTranslation} from 'react-i18next';
import {useFeatureToggle, useModal} from '@hooks';
import {ExtrasTypes, getExtrasBySpecificType, useAppSelector} from '@lib/store';
import {Icon} from '@atoms';
import {byProp} from '@lib/utils';
import {ExtrasSelectionCard} from '@organisms';
import {P, Span} from '@quarks';
import {getExtraItem} from '@utils';
import {ComponentProps} from 'react';
import {ExtrasSelectionCellProps} from 'src/components/organisms/extras/ExtrasSelectionCell/types';
import {TileItem} from '../../TileItem';

export const ExtrasTile = ({date, ...props}: ComponentProps<typeof TileItem> & {date: string}) => {
  const {t} = useTranslation('extras');
  const {addPages, openModal} = useModal();
  const {ExtrasFeature: isFeatureEnabled} = useFeatureToggle();

  const max = 1;
  const extrasType = ExtrasTypes.WORKDAY;

  const allExtras = useAppSelector((state) => getExtrasBySpecificType(state, extrasType))
    .map(getExtraItem)
    .sort(byProp('name'));

  if (!isFeatureEnabled || !allExtras.length) return <></>;

  const handleShowExtrasInModal = () => {
    addPages([
      <ExtrasSelectionCard
        extrasType={extrasType}
        extrasItems={allExtras}
      />,
    ]);
    openModal();
  };

  const visibleExtras =
    allExtras.length > max
      ? [
          {
            id: extrasType,
            iconLeft: 'extras',
            iconRight: 'caretRight',
            description: '',
            onClick: handleShowExtrasInModal,
            name: t('AddExtras'),
          } as ExtrasSelectionCellProps,
        ]
      : allExtras.slice(0, max);

  return (
    <>
      {visibleExtras.map(({iconLeft, iconRight, name, description, onClick}, index) => {
        return (
          <TileItem
            key={`${name}_${index}`}
            data-testid="workday-tile"
            onClick={onClick}
            {...props}>
            <Icon
              className="size-5"
              icon={iconLeft}
            />
            <div>
              <Span fontWeight="bold">{name}</Span>
              <P
                color="currentColor"
                fontWeight="regular">
                {description}
              </P>
            </div>

            <Icon
              className="size-5 justify-self-end"
              icon={iconRight}
            />
          </TileItem>
        );
      })}
    </>
  );
};
