import {DayName, getAccessProfile, getIsParkingFeatureVisible, getParkingQuotaProfile} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {StyledQuotaCard} from './styles';

export const QuotaCard = () => {
  const {t} = useTranslation();

  const {daysAhead, daysPerWeek, registrationDays} = useAppSelector(getAccessProfile);

  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);

  const parkingQuotaProfile = useAppSelector(getParkingQuotaProfile);

  const daysInAWeek = 7;
  const shiftsEachWeekText = t('profile:WorkspaceEachWeek', {count: daysPerWeek});
  const amountInAdvanceText =
    daysAhead % daysInAWeek === 0
      ? t('profile:WeeksInAdvance', {count: daysAhead / daysInAWeek})
      : t('profile:DaysInAdvance', {count: daysAhead});
  const onWeekDaysText = t('profile:OnWeekDays');
  const weekDays: DayName[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const isAllWeek = registrationDays.length === daysInAWeek;
  const isWeekDaysOnly =
    registrationDays.length === weekDays.length && weekDays.every((d) => registrationDays.includes(d));
  const allowedDaysText = isAllWeek
    ? ''
    : isWeekDaysOnly
    ? onWeekDaysText
    : registrationDays.reduce((previous, next, idx: number) => {
        if (registrationDays.length === 0) {
          return '';
        }
        if (registrationDays.length === 1) {
          return next;
        }
        return idx === 0
          ? next
          : idx === registrationDays.length - 1
          ? `${t(previous as DayName)} ${t('And').toLowerCase()} ${t(next as DayName)}`
          : `${t(previous as DayName)}, ${t(next as DayName)}`;
      }, '');
  const isAllowedDaysShown = allowedDaysText !== '';

  return (
    <StyledQuotaCard lineHeight="body">
      <ul>
        <li>{shiftsEachWeekText}</li>
        {isParkingFeatureVisible && (
          <li>{t('parking:ParkingSpotEachWeek', {count: parkingQuotaProfile.daysPerWeek})}</li>
        )}
        <li>{amountInAdvanceText}</li>
        {isAllowedDaysShown && <li>{allowedDaysText}</li>}
      </ul>
    </StyledQuotaCard>
  );
};
