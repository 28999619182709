import {
  getAllBuildings,
  getDefaultBuildingId,
  getEnabledFeaturesLoadingStatus,
  getIsOnboarded,
  getIsParkingFeatureVisibleForDefaultBuilding,
  getIsSignedIn,
} from '@lib/store';
import {useAppSelector} from '@hooks';

/**
 * * CONTROLS
 * We present the navigation if our user is:
 * - signed in, and
 * - not new.
 * Furthermore, if the user has no default building set, we
 * present the navigation only if there are 1 or 0 buildings
 * to choose from. If there are more than 1 building to choose
 * from and the user has not selected a default building yet
 * we want them to go through the building selection of the onboarding flow.
 */

export const useShowNavigation = () => {
  const isSignedIn = useAppSelector(getIsSignedIn);
  const enabledFeaturesLoadingStatus = useAppSelector(getEnabledFeaturesLoadingStatus);
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisibleForDefaultBuilding);
  const isOnboarded = useAppSelector((state) => getIsOnboarded(state, isParkingFeatureVisible));
  const buildings = useAppSelector(getAllBuildings);
  const isDefaultBuildingSet = !!useAppSelector(getDefaultBuildingId);

  const showNavigation =
    isSignedIn &&
    enabledFeaturesLoadingStatus === 'Loaded' &&
    isOnboarded &&
    (isDefaultBuildingSet || buildings.length <= 1);

  return showNavigation;
};
