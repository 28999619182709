import {useTranslation} from 'react-i18next';

import {WorkspaceReservation} from '@lib/store';
import {useAcceptDeclineWorkdayInvitation, useModal} from '@hooks';

import {BookingDetailsCard} from '@organisms';
import {ComponentProps} from 'react';
import {Icon} from '@atoms';
import {TileItem} from '../../TileItem';
import {TileMapPreview} from '../../TileMapPreview';
import {getGroupInvitationExpiryTime} from '@lib/utils';
import {Avatar, Button} from '@molecules';

export const GroupBookingTile = ({
  date,
  workspaceReservation,
  showMapPreview,
  ...props
}: ComponentProps<typeof TileItem> & {
  date: string;
  workspaceReservation: WorkspaceReservation;
  showMapPreview?: boolean;
}) => {
  const {id, owner, currentMember, groupReservationId, status} = workspaceReservation;
  const {t} = useTranslation();
  const {openModal, setModalPages} = useModal();
  const {acceptInvitation, declineInvitation, loading} = useAcceptDeclineWorkdayInvitation({
    date,
    workspaceReservationId: id,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.click();
    }
  };

  if (!owner) return null;

  return (
    <>
      {showMapPreview && (
        <TileMapPreview
          key={'test'}
          workspaceReservation={workspaceReservation}
        />
      )}
      <TileItem
        data-testid="workspace-tile"
        aria-label={t('workplace:GroubBookingInvitationAriaLabel', {displayName: owner.user.displayName})}
        onKeyDown={handleKeyDown}
        onClick={() => {
          setModalPages([
            <BookingDetailsCard
              date={date}
              workspaceReservation={workspaceReservation}
            />,
          ]);
          openModal();
        }}
        {...props}>
        <Avatar
          size={'small'}
          user={owner.user}
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h4>{t('workplace:GroupBooking')}</h4>
            <span>
              <p>
                {t('workplace:GroupReservationBookedBy', {
                  firstName: owner.user.firstName,
                  lastName: owner.user.lastName,
                })}
              </p>
              {currentMember?.expiryDateTime && (
                <p className="text-mapiq-black-500">{getGroupInvitationExpiryTime(t, currentMember.expiryDateTime)}</p>
              )}
            </span>
          </div>

          <div className="flex gap-2">
            <Button
              as="div"
              onClick={(e) => {
                e.stopPropagation();
                if (groupReservationId) {
                  declineInvitation();
                }
              }}
              loading={loading}
              button="secondary"
              size="small">
              {t('translation:Decline')}
            </Button>
            <Button
              as="div"
              onClick={(e) => {
                e.stopPropagation();
                if (groupReservationId) {
                  acceptInvitation();
                }
              }}
              loading={loading}
              button="primary"
              size="small">
              {t('translation:Accept')}
            </Button>
          </div>
        </div>

        <Icon
          className="size-5 justify-self-end"
          icon={'caretRight'}
        />
      </TileItem>
    </>
  );
};
