export enum OnboardingStep {
  Intro,
  BookingPermissions,
  PreferencesIntro,
  Building,
  Floor,
  Area,
  DeskAmenities,
  ParkingLot,
  ParkingZone,
  Overview,
}
