import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {getDefaultBuildingId, useAppSelector} from '@lib/store';
import {nanoid} from '@reduxjs/toolkit';
import {OnboardingStep} from '../types';
import {useFloorOptions} from '@lib/logic';

interface OnboardingFloorProps {
  initialValue: string | null;
  onBack?: () => void;
  onSelectFloor: (selectedFloorId: string | null) => void;
}

export const OnboardingFloorCard = ({initialValue, onBack, onSelectFloor}: OnboardingFloorProps) => {
  const {t} = useTranslation();
  const buildingId = useAppSelector(getDefaultBuildingId);
  const floorOptions = useFloorOptions({buildingId});

  return (
    <OnboardingCard
      items={floorOptions}
      title={t('workplace:WhichFloorPreference')}
      description={t('workplace:WhichFloorPreferenceDescription')}
      currentStep={OnboardingStep.Floor}
      initialValue={initialValue}
      renderItem={({item: floor, selectedItem, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={floor.value ?? nanoid()}
          onFocus={handleOnFocus}>
          <Input
            type="radio"
            name="floor"
            checked={selectedItem === floor.value}
            onChange={() => setSelectedItem(floor.value)}
          />
          <LabelText>{floor.label}</LabelText>
        </OnboardingItemCell>
      )}
      onBack={() => onBack?.()}
      onSelect={onSelectFloor}
    />
  );
};
