import {useTranslation} from 'react-i18next';
import {
  getAreAllParkingNodesClosed,
  getAreAllParkingNodesFull,
  getIsUserAllowedToReserveParking,
  getParkingQuotaHasBeenReached,
  getParkingReservationByDate,
  getParkingWeekReservationsPerDate,
  getWorkspaceQuotaInfo,
} from '@lib/store';
import {useAppSelector} from '@hooks';
import {parseISO} from 'date-fns';

import {P} from '@quarks';
import {useMemo} from 'react';

export const CalendarParkingTileBookingInfo = ({buildingId, date}: {buildingId: string; date: string}) => {
  const {t} = useTranslation();
  const dateAsDate = useMemo(() => parseISO(date), [date]);

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const {bookingCount: parkingBookingCount, maxBookings: maxParkingBookings} = useAppSelector((state) =>
    getParkingWeekReservationsPerDate(state, dateAsDate),
  );
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, dateAsDate, buildingId),
  );
  const allClosed = useAppSelector((state) => getAreAllParkingNodesClosed(state, dateAsDate, buildingId));
  const allFull = useAppSelector((state) => getAreAllParkingNodesFull(state, dateAsDate, buildingId));
  const {isSelectedDateInPast, isSelectedDateFuture, isDayAllowed} = useAppSelector((state) =>
    getWorkspaceQuotaInfo(state, dateAsDate),
  );
  const quotaHasBeenReached = useAppSelector((state) => getParkingQuotaHasBeenReached(state, dateAsDate));
  const isEnabled = isUserAllowedToReserveParking || parkingReservation;

  const title = useMemo(() => {
    if (parkingReservation) return parkingReservation.parkingLotName;

    if (isUserAllowedToReserveParking) return t('parking:ParkingSuggestionPositiveButton');
    else {
      if (isSelectedDateInPast) return t('workplace:NotAllowedToBook');

      if (isSelectedDateFuture) return t('workplace:LocationTooFarAhead');

      if (quotaHasBeenReached) return t('QuotaHasBeenReached');

      if (!isDayAllowed) return t('workplace:NotAllowedToBook');

      if (allClosed) return t('parking:AllParkingsClosed');

      if (allFull) return t('parking:AllParkingsFull');

      return t('parking:ParkingNotAvailable');
    }
  }, [
    allClosed,
    allFull,
    isDayAllowed,
    isSelectedDateFuture,
    isSelectedDateInPast,
    isUserAllowedToReserveParking,
    parkingReservation,
    quotaHasBeenReached,
    t,
  ]);

  return (
    <>
      <P fontWeight="bold">{title}</P>
      {isEnabled && (
        <P
          fontWeight="regular"
          color="currentColor">
          {parkingReservation
            ? parkingReservation.zoneName
            : t('parking:ParkingBookingLeftPerWeek', {count: maxParkingBookings - parkingBookingCount})}
        </P>
      )}
    </>
  );
};
