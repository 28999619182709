import {useTranslation} from 'react-i18next';
import {Button} from '@molecules';
import {FlexCol, H1, P} from '@quarks';
import {CardBody, CardBottom, CardTop} from '../styles';
import img from '@images/onboarding/intro.webp';
import {OnboardingWrapper} from '@organisms';
import {BackButton} from '../components';

interface OnboardingIntroCardProps {
  onNext: () => void;
  onBack?: () => void;
}

export const OnboardingIntroCard = ({onNext, onBack}: OnboardingIntroCardProps) => {
  const {t} = useTranslation();

  return (
    <OnboardingWrapper>
      <CardTop>
        <FlexCol gap={16}>
          {onBack && <BackButton onBack={onBack} />}

          <H1 id="onboarding-legend">{t('workplace:OnboardingStartTitle')}</H1>
          <P>{t('workplace:OnboardingStartDescription')}</P>
        </FlexCol>
      </CardTop>

      <CardBody className="min-h-40 py-4">
        <img
          src={img}
          className="h-full w-auto mx-auto object-contain"
        />
      </CardBody>

      <CardBottom>
        <Button
          button="primary"
          onClick={onNext}
          size="full">
          {t('workplace:GetStarted')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
