import {Navigation} from '@organisms';

import {useFeatureToggle, useShowNavigation} from '@hooks';
import {StyledFooterLayout} from './styles';
import {Navigation_ExperienceImprovements} from '../../Navigation_ExperienceImprovements';

export const Footer = () => {
  const showNavigation = useShowNavigation();
  const enabledFeatures = useFeatureToggle();

  if (!showNavigation) {
    return <></>;
  }

  return enabledFeatures.ExperienceImprovements ? (
    <div
      className="flex sm:hidden items-center justify-center border-t sticky px-4 bottom-0 z-10 bg-white"
      style={{gridArea: 'footer / fullbleed'}}>
      {showNavigation && <Navigation_ExperienceImprovements footer />}
    </div>
  ) : (
    <StyledFooterLayout>
      <Navigation footer />
    </StyledFooterLayout>
  );
};
