import {ContextsProps} from './types';
import {DialogProvider, ModalProvider, ThemeProvider, SidePanelProvider} from '@contexts';
import {FiltersProvider, GlobalImageCacheProvider} from '@lib/logic';

export const Contexts = ({children}: ContextsProps) => {
  return (
    <ThemeProvider>
      <GlobalImageCacheProvider>
        <DialogProvider>
          <ModalProvider>
            <FiltersProvider>
              <SidePanelProvider>{children}</SidePanelProvider>
            </FiltersProvider>
          </ModalProvider>
        </DialogProvider>
      </GlobalImageCacheProvider>
    </ThemeProvider>
  );
};
